@import "../../styles//variables.scss";
.print_workorder_modal {
  height: 100%;
  width: 100%;
  overflow: hidden;
  & > header {
    display: grid;

    grid-template-columns: repeat(7, 1fr);
    gap: 1rem;
    // background-color: $primary-blue;
    border-bottom: 1px solid rgb(33, 21, 81);
    padding: 1rem;
    place-items: end;
    & > div {
      display: flex;
      gap: 1rem;
      grid-column-start: 7;
      align-items: center;
    }
    & > h3 {
      color: $white;
      color: $primary-blue;
      grid-column-start: 4;
      width: max-content;
      text-align: center;
    }
    & button {
      outline: none;
      border: none;
      cursor: pointer;
      font-size: 1.2rem;
      background: none;
      color: white;
      color: $primary-blue;
      font-weight: bold;
    }
    & svg {
      fill: white;
      fill: $primary-blue;
      cursor: pointer;
    }
  }
  &__body {
    overflow-y: auto;
    height: 89%;
    padding: 1rem;
    &__section {
      overflow: hidden;
      height: max-content;
      padding-top: 0.3rem;

      .businesstable {
        min-height: max-content;

        max-height: 50vh;
        margin: 5px 0px 9px 3px;
        border: 1px solid $primary-blue;
      }
      table {
        width: 100%;
        border-radius: 4px;
        background-color: $primary-blue;
        font-size: 14px;
        margin: 0.5rem 0.5rem 0.5rem 0;
        thead {
          background-color: $primary-blue;
          color: white;
          tr {
            text-align: left;
            th {
              padding: 0.5rem;

              height: 26px;
              &:nth-child(1) {
                background-color: $primary-blue;

                padding-left: 5px;
                text-align: left;

                border-right: 1px solid $white;
              }
              &:nth-child(2) {
                background-color: $primary-blue;

                border-right: 1px solid $white;
              }
              &:nth-child(3) {
                background-color: $primary-blue;

                border-right: 1px solid $white;
              }

              &:nth-child(n + 3) {
                border-right: 1px solid $white;
                margin-right: 1px;
              }
              &:last-child {
                border-right: none;
              }
            }
          }
        }
        tbody {
          tr {
            &.hide {
              display: none;
            }
            td {
              padding: 0.5rem;
              text-align: left;
              font-weight: normal;
              &:nth-child(1) {
                padding-left: 5px;
                text-align: left;

                background: white;
              }
              &:nth-child(2) {
                background: white;
              }
              &:nth-child(3) {
                background: white;
              }
              &:nth-child(n + 3) {
              }
            }
            background-color: $white;
          }
        }
      }
      &__emptyState {
        font-size: $f14;
        color: $dark-gray;
        font-style: italic;
      }
      .btn-add_new {
        margin-top: 1rem;
      }
    }
  }
  & .sectionContainer {
    min-height: max-content;
    & > .title {
      font-size: 1rem;
    }
  }

  & .businessRulesBasicRules,
  .businesWorkOrderRules,
  .businessOptionalRules {
    box-shadow: none;
    margin-top: 1rem;
    min-height: max-content;
    & > .title {
      font-size: 0.8rem !important;
    }
  }
  & .businessRulesBasicRules {
    margin-top: 2rem;
  }
  & .businessOptionalRules {
    & > .title {
      font-size: 0.8rem !important;
    }

    box-shadow: none;
  }
}
