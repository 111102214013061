.content {
  position: absolute;
  bottom: 0;
  z-index: 6;
  font-size: 12px;
  width: fit-content;
  background-color: rgba(255, 255, 255, 1);
  align-self: flex-start;
  margin-top: 12px;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(87, 84, 99, 0.2);
}

.arrowContainer {
  position: absolute;
  top: -8px;
  left: 12px;
  width: 16px;
  height: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  .arrow {
    position: relative;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #fff;
  }
}

.arrowDown {
  position: absolute;
  bottom: -8px;
  left: 12px;
  width: 16px;
  height: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  .arrow {
    position: relative;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid #fff;
  }
}

.borderline {
  position: absolute;
  background-color: rgba(0, 183, 224, 1);
  width: 4px;
  height: 100%;
  top: 0;
  left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.borderline_down {
  position: absolute;
  background-color: #211551;
  width: 4px;
  height: 100%;
  top: 0;
  left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.options {
  color: rgba(53, 34, 129, 1);
  font-size: 10px;
  font-weight: 400;
  background-color: inherit;
  padding: 12px 16px 12px 12px;
  white-space: nowrap;
}
