.browser_print {
  position: absolute;
  width: max-content;
  background: white;
  color: #211451;

  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(33 30 81);
  display: none;
  align-items: center;
  flex-direction: row;
  gap: 0.1rem;
  z-index: 9999;
  cursor: pointer;
  font-weight: bold;

  flex-direction: column;
  & > div {
    &:hover {
      background-color: rgb(181, 177, 235);

      &:first-child {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }

      &:nth-child(2) {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    & > svg {
      font-size: 1.2rem;
    }
    & > p {
      padding: 0.5rem 0;
    }
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    padding: 0 0.5rem;
    height: inherit;
  }
}

.toast_alter {
  & > div {
    button {
      display: none;
    }
  }
}

.toast_styling {
  position: fixed;
  padding: 4px;
  width: max-content;
  box-sizing: border-box;

  z-index: 9999;
  top: 2%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 5px;
  color: #7c7c7e;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  box-shadow: 0px 8px 16px 0px rgba(34, 17, 17, 0.2);
  & > svg {
    fill: #07bc0c;
  }
  & > p {
    display: flex;
    padding: 0.5rem;
  }
}
