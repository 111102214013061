@import "../../styles/variables.scss";

.pricingCalc {
  margin: auto;
  width: 100%;
  min-height: 120px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  &__table {
    width: 100%;
    padding-left: 0.5rem;

    &__header {
      text-align: left;
      display: table;
      width: 100%;
      table-layout: fixed;
      th:nth-child(1) {
        width: 10%;
      }
      th:nth-child(2) {
        width: 25%;
      }
      th:nth-child(3) {
        width: 10%;
      }
      th:nth-child(4) {
        width: 34%;
      }

      th:nth-child(5) {
        width: 21%;
      }
      &__cell {
        text-align: left;
      }
    }
    &__body {
      display: block;
      height: 217px;
      overflow: auto;
      text-align: left;
      &__strikethrough {
        & td {
          text-decoration: line-through rgb(33, 21, 81);
          opacity: 0.5;
        }
        & :last-child {
          opacity: 1;
          text-decoration: none;
        }
        &__cell {
          text-align: left;
          padding-left: 0.2rem;
        }
      }
      tr {
        padding: 5px 0;
        font-size: $f14;
        height: 32px;
        display: table;
        width: 100%;
        table-layout: fixed;

        td {
          overflow-wrap: break-word;
        }
        td:nth-child(even) {
          font-weight: bold;
        }
        td:nth-child(1) {
          width: 10%;
        }
        td:nth-child(2) {
          width: 25%;
        }
        td:nth-child(3) {
          width: 10%;
          padding-left: 1px;
        }
        td:nth-child(4) {
          width: 34%;
        }

        td:nth-child(5) {
          width: 21%;
        }
        td:last-child {
          display: grid;
          text-align: end;
          grid-gap: 2.6rem;
          place-items: end;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          width: 100%;
          & > :last-child {
            grid-column-start: 3;
            grid-column-end: 5;
          }
        }
        .btn-table {
          padding: 4px 26px;
          border-radius: 5px;
          position: relative;
          grid-column-end: 3;
          grid-column-start: 2;
        }
      }

      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-button {
        background: #eee;
      }
      &::-webkit-scrollbar-track-piece {
        background: #eee;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(165, 165, 165);
      }
    }
  }
  &__emptyState {
    font-size: 1.5rem;
    color: $dark-gray;
    font-style: italic;
    bottom: 7rem;
    font-weight: normal;
  }
  .btn-add_new {
    margin-top: 1rem;
  }
}
