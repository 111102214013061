.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & > .container12 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    padding-top: 32px;
    padding-bottom: 32px;
    overflow-y: auto;

    & > .container13 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;

      & > h2 {
        color: rgb(53, 34, 129);
        font-size: 36px;
        font-family: "Museo Slab";
        font-weight: 400;
        letter-spacing: -0.01em;
        line-height: 48px;
        text-align: center;
        margin-top: 0;
      }

      & > .container11 {
        display: flex;
        flex-direction: column;

        & > *:not(:first-child) {
          margin-top: 16px;
        }
      }

      & > *:not(:first-child) {
        margin-top: 32px;
      }
    }
  }
}

$w1: 320px;
$g1: 6px;
$w2: calc(($w1 * 3) + ($g1 * 2));

h2 {
  color: rgb(53, 34, 129);
  font-size: 36px;
  font-family: "Museo Slab";
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 48px;
  margin-top: 1.5rem;
}

.hFull {
  height: 100%;
}

.flex {
  display: flex;
}

.flexCol {
  flex-direction: column;
}

.flexRow {
  flex-direction: row;
}

.flexWrap {
  flex-wrap: wrap;
}

.gap1 {
  gap: $g1;
}

.gap2 {
  gap: 36px;
}

.textColor1 {
  color: #575463;
}

.textColor2 {
  color: #100a27;
}

.textSize1 {
  font-size: 11px;
}

.textSize2 {
  font-size: 13px;
}

.textRight {
  text-align: right;
}

.textRoboto {
  font-family: "Roboto";
}

.bgColor1 {
  background-color: #eae9f2;
}

.hoverBgColor1:hover {
  background-color: #ffffff;
}

.rounded1 {
  border-radius: 5px;
}

.px16 {
  padding-left: 16px;
  padding-right: 16px;
}

.py12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.spaceXAuto > *:not(:first-child) {
  margin-left: auto;
}

.spaceX1 > *:not(:first-child) {
  margin-left: 6px;
  padding-left: 6px;
}

.spaceY1 > *:not(:first-child) {
  margin-top: 4px;
}

.w1 {
  width: $w1;
}

.w2 {
  width: $w2;
}

.childBorderX > *:not(:first-child) {
  border-left-width: 1px;
  border-left-style: solid;
}

.childBorderColor1 > *:not(:first-child) {
  border-color: #c4c4c4;
}

.justifyCenter {
  justify-content: center;
}

.itemsStart {
  align-items: start;
}

.itemsCenter {
  align-items: center;
}

.hoverShadow1:hover {
  box-shadow: 0px 1px 2px 0px #a199c214;
  box-shadow: 0px 2px 4px 0px #a199c21f;
  box-shadow: 0px 4px 8px 0px #a199c229;
  box-shadow: 0px 8px 16px 0px #a199c233;
}

input[type="radio"].radio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

input[type="radio"].radio + label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

input[type="radio"].radio + label:before {
  content: "";
  position: absolute;
  left: 0;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #a199c2;
}

.group:hover input[type="radio"].radio.groupHover + label:before {
  border-radius: 50%;
  border: 1px solid rgba(161, 153, 194, 0.5);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(161, 153, 194, 0.08),
    0px 2px 4px 0px rgba(161, 153, 194, 0.12),
    0px 4px 8px 0px rgba(161, 153, 194, 0.16),
    0px 8px 16px 0px rgba(161, 153, 194, 0.2);
}

input[type="radio"].radio:checked + label:before {
  border-radius: 50%;
  border: 1px solid #150b3b;
  background: #fff;
}

input[type="radio"].radio:checked + label:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #150b3b;
}

.cursorPointer {
  cursor: pointer;
}

.pointerEventsNone {
  pointer-events: none;
}

.btn_position {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  margin: 0 2rem;
  padding-right: 0.6rem;
  padding-bottom: 5rem;
  align-items: center;
}

.btn_action_position {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: max-content;
  margin: 0 2rem;
  padding-right: 0.6rem;

  gap: 16px;
  align-items: center;
}
.buttons_position_withouterror {
  top: 0.9rem;
  left: 1%;
  width: 100%;
  padding-right: 0px;
  margin-right: 0px;
}

.add_space_for_errormessage {
  justify-content: space-between;
}

.btn_calcel_styling {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
  color: #352281;
  font-weight: 500;
  font-size: 16px;
  background: none;
  border: none;
  gap: 6px;
  &:hover {
    color: #352281;
    background: none;
  }
}

.btn_update_styling {
  width: max-content;
  cursor: pointer;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  background-color: #1559ea;
  color: white;
  border: none;
  &:hover:not(:active, :disabled) {
    background: #3270eb;
    box-shadow: 0px 1px 2px 0px rgba(21, 89, 234, 0.08),
      0px 2px 4px 0px rgba(21, 89, 234, 0.12),
      0px 4px 8px 0px rgba(21, 89, 234, 0.16),
      0px 8px 16px 0px rgba(21, 89, 234, 0.2);
    backdrop-filter: blur(100px);
  }
  &:active {
    background: #1046b8;
  }
  &:disabled {
    background: rgba(21, 89, 234, 0.25);
    color: white;
  }
}

.left_arrow {
  font-size: 1rem;
}
.sow_rates_height {
  overflow-y: auto;
  height: 22rem;
}

.hourly_rates_height {
  height: 7.7rem;
  overflow-y: auto;
}
.multiple_unapproved_records {
  color: red;
  font-size: 1rem;
  padding-left: 2.5rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.clientContainer {
  border-left: 1px solid #d9d9d9;
}
