@import "../../styles/variables.scss";

@mixin list_style {
  display: inline-block;
  text-decoration: none;
}

@mixin list_object_style {
  display: grid;
  grid-template-columns: 1fr 0.3fr 1fr;
}
@function list_first_div_child() {
  @return 1rem 2rem 1rem 2rem;
}
.simulatepay {
  width: 100%;
  position: relative;
  font-size: $f14;
  width: 100%;
  min-height: 150px;
  position: relative;
  display: grid;
  padding: 1rem 2rem;

  border-radius: 5px;
  h2 {
    text-decoration: underline;
  }
  &__error {
    position: relative;
    border-radius: 10px;
    width: 60%;
    left: 11rem;
    top: 1rem;
    height: max-content;
    background: rgb(255 207 209 / 50%);
    display: grid;
    place-content: center;
    align-items: center;
    padding: 1rem;
    grid-template-columns: 0.01fr 1fr;
    height: max-content;

    button {
      width: 24px;
      height: 24px;
      background: #f13737;
      outline: none;
      border-radius: 50%;
      transform: rotate(45deg);
      margin-right: 10px;
      border: none;
      color: #000;
      font-size: 1.1rem;
      padding-left: 2px;
      padding-bottom: 3px;
    }
    p {
      color: $black;
      font-size: 1.2em;
      font-weight: normal;
      text-align: left;
      margin-left: 9px;
    }
  }

  &__header {
    display: grid;
    grid-template-columns: 8rem 1fr;
    grid-gap: 20px;
    align-items: center;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 2px 13px rgb(0 0 0 / 25%);

    &__label {
      display: grid;
      justify-content: end;
      align-content: center;
      &__parent {
        display: grid;
        grid-template-columns: 1fr 0.2fr;
        &__input_label {
          outline: none;
          // grid-column: 2 / 4;
          & input {
            border: 1px solid $gray !important;
            border-right: none !important;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
          }
        }
        & button {
          margin-left: -10px;
          border-left: none;
          border-top-left-radius: 0px;
          background-color: $primary-blue;
          color: white;
          border-bottom-left-radius: 0px;
        }
        &__calcbtn {
          background-color: $cyan-blue;
          border-radius: 5px;
          border: 1px solid black;
          cursor: pointer;
        }
        &__disableCalculatebtn {
          cursor: not-allowed;
          background-color: $gray !important;
          border-color: $gray !important;
          color: $dark-gray !important;
        }
      }
    }
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    margin-top: 3rem;
    font-size: 1.5rem;
    justify-content: center;
    grid-row-gap: 16px;
    grid-column-gap: 13px;
    &__rates {
      &:first-child {
        grid-column-start: 1;
        grid-column-end: 4;
        & div {
          border-right: none;
        }
      }
      & > div {
        & div[data-name="billrate"] {
          // border-top: 1px solid $primary-blue;
          &:hover {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
          }
        }
      }
      &:nth-child(2) {
        height: 100%;
        grid-column-start: 4;
        grid-column-end: 11;
        & > div {
          grid-template-columns: 1fr 1fr;
          height: calc(100% - 48px);
          display: grid;
          & > :first-child {
            border-right: 1px solid;
          }
        }
      }
      background-color: $white;
      color: $primary-blue;
      border-radius: 10px;
      box-shadow: 0 2px 13px rgb(0 0 0 / 25%);

      &__label {
        font-size: 1rem;
        border-bottom: 1px solid $primary-blue;
        padding: 1rem;
        height: 3rem;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      &__parent {
        &__child {
          font-size: 1rem;
          place-items: center;
        }
        &__child > span {
          text-decoration: none;
        }

        // &__child:hover div:last-child {
        //   border-bottom-right-radius: 10px;
        //   border-bottom-left-radius: 10px;
        // }

        &__child div {
          padding: 1rem 0.5rem 1rem 1rem;
          & > span,
          & > i {
            text-align: left;
          }

          display: grid;
          grid-template-columns: 0.8fr 1fr;
          width: 100%;
          grid-column-gap: 2rem;
          i {
            font-size: 0.8rem;
            font-weight: bold;
          }
          i ~ span {
            font-size: 0.8rem;
          }
        }
        &__child span:first-child {
          font-weight: bold;
        }
        &__child span {
          @include list_style;
          text-align: end;
        }
        &__child span[data-rate] {
          text-align: start;
        }
      }

      &__object {
        font-size: 1rem;
        @include list_object_style;
      }
    }
    &__attribution {
      grid-column-start: -11;
      grid-column-end: -1;
      border-radius: 10px;
      height: max-content;
      &__list {
        h5 {
          border-bottom: 1px solid $primary-blue;
        }
        &__label {
          font-size: 1rem;
          border-bottom: 1px solid $white;
          padding: 1rem;
        }

        font-size: 0.9rem;
        background-color: $white;
        color: $primary-blue;
        border-radius: 10px;
        box-shadow: 0 2px 13px rgb(0 0 0 / 25%);
        &__object {
          font-size: 1rem;
          text-align: left;

          @include list_object_style;

          position: relative;
          align-items: baseline;
          &__rules {
            display: none;
            &__attrheader {
              position: relative;
              width: 100%;
              height: max-content;
              padding: 1rem;
              font-weight: bold;
              border-bottom: 1px solid $primary-blue;
              font-size: 0.9rem;
              display: grid;
              grid-template-columns: 1fr 0.5fr 1fr;

              gap: 1rem;
              text-align: left;
            }
            &__attrbody {
              position: relative;
              width: 100%;
              height: max-content;
              padding: 1rem;
              font-size: 0.8rem;
              display: grid;
              grid-template-columns: 1fr 0.5fr 1fr;

              gap: 1rem;
              text-align: left;
            }
          }

          &__attrList {
            padding: 1rem 1rem;
            text-align: left;
            line-height: 24px;
            &:first-child {
              width: max-content;
              cursor: pointer;
            }
            svg {
              padding-top: 6px;
              top: 3px;
            }

            &:first-child:hover > div:last-child {
              box-shadow: 0 2px 13px rgb(0 0 0 / 70%);
              position: absolute;
              width: 35rem;
              height: 11rem;
              overflow-y: auto;
              border-radius: 10px;
              left: 15%;
              bottom: 3.1rem;

              display: flex;
              transition: all 2s linear;
              flex-direction: column;
              background-color: $white;
              color: $primary-blue;
            }
          }

          gap: 1rem;
          text-align: left;
        }
      }
      &__list > span {
        text-decoration: none;
      }
      &__list div:first-child {
        margin: list_first_div_child();
      }

      &__list > span:first-child {
        display: grid;
        font-weight: bold;
      }
      &__list > span:nth-child(3) {
        display: grid;
        justify-items: flex-start;
      }

      &__list > span {
        @include list_style;
        text-align: left;
      }
    }
    &__quoterate_rates {
      grid-column-start: 1 !important;
      grid-column-end: 4 !important;
      border-radius: 10px;
      height: max-content;
    }
    &__quoterate_attribution {
      grid-column-start: 4 !important;
      grid-column-end: 11 !important;
      border-radius: 10px;
      height: max-content;
    }
  }
}
