@import "../../styles//variables.scss";
.container {
  height: max-content;
  width: max-content;
  position: static;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  text-decoration: none;
}
.floating_button {
  position: absolute;
  right: 1.5%;
  bottom: 2%;
}
.main_button {
  display: none;
}
.main_button + label {
  z-index: 999;
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: $primary-blue-hover;
}
.main_button + label::after,
.main_button + label::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 3px;
  background: #fff;
  left: 50%;
  top: 50%;
  transition: 0.1s ease;
  font-size: 10px;
}
.main_button + label::after {
  transform: translate(-50%, -2px) rotate(88deg);
}
.main_button + label::before {
  transform: translate(-50%, -2px) rotate(0deg);
}
.main_button:checked + label {
  background: $primary-blue-hover;
}
.main_button:checked + label::after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.main_button:checked + label::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.roundButton {
  top: 0px;
  left: 0px;
  z-index: 10;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  transition: transform 0.1s ease;
}
.roundButton .icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
.buttonShare {
  transition-delay: 0s;
  background: #9b59b6;
}
.buttonPrint {
  transition-delay: 0.1s;
  background: $primary-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &:hover {
    &::after {
      content: "Print";
      position: absolute;
      font-weight: bold;
      width: max-content;
      top: -1.8rem;
      box-shadow: 0 3px 10px rgb(32 19 79);
      height: max-content;
      background: white;
      padding: 0.12rem 0.3rem;
      border-radius: 4px;
    }
  }
  & > svg {
    fill: white;
  }
}
.buttonNew {
  transition-delay: 0.2s;
  background: #8e44ad;
}
.main_button:checked ~ .buttonShare {
  transform: translate(0, -70px);
}
.main_button:checked ~ .buttonPrint {
  transform: translate(0px, -55px);
}
.main_button:checked ~ .buttonNew {
  transform: translate(-70px, 0);
}
