.search_parent.search_parent_helper {
  gap: 1rem;
  align-items: start;
  position: absolute;
  top: 45%;
  left: 5%;
}

.close_icon {
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  left: 95%;
  cursor: pointer;
  outline: none;
  background: none;
  padding-top: 2rem;
}
