.error_style {
  margin: 2rem;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 1.3rem;

  background: white;
  height: 4rem;
  width: max-content;
  border-radius: 5px;
  padding: 1rem;
  border-left: 6px solid #c84060;
  position: relative;
  padding-left: 2.5rem;
  display: flex;
  align-items: center;
  &::after {
    content: "!";
    position: absolute;
    width: 20px;
    font-size: 1rem;
    vertical-align: center;
    text-align: center;
    color: white;
    height: 20px;
    border-radius: 50%;
    background-color: #c84060;

    left: 10px;
  }
}

.error_component_parent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
}
