.alitn_back_button {
  display: flex;
  gap: 0.5rem;
  font-size: 0.9rem;
  cursor: pointer;
}

.left_arrow {
  font-size: 1rem;
}

.btn_calcel_styling {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
  color: #352281;
  font-weight: 500;
  font-size: 16px;
  background: none;
  border: none;
  gap: 6px;
  &:hover {
    color: #352281;
    background: none;
  }
}
