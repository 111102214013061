@import "../../styles/variables.scss";
.manageRulesParent {
  .action_btn_styles {
    display: flex;

    justify-content: space-around;
    width: 45%;
    & > svg {
      cursor: pointer;
    }
  }
  overflow-y: auto;
  height: calc(100vh - 63px);
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-button {
    background: #eee;
  }
  &::-webkit-scrollbar-track-piece {
    background: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(165, 165, 165);
  }
  &__main {
    width: clamp(50rem, 90vw, 95rem);
    margin: 0 auto;

    &__locationerror_parent {
      display: flex;
      justify-content: end;
      margin-right: 1px;
      & span {
        font-size: 0.9rem;
        font-weight: normal;
        display: flex;
        & > p {
          font-weight: bold;
        }
      }
      &__message {
        margin-bottom: -5px;
        width: max-content;
        display: flex;
        color: blue;
        padding: 1rem;
        border-radius: 12px;
        gap: 0.5rem;
        align-items: center;
        position: relative;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border: 1px solid #cccccc;
        border-bottom: 0px;
        div {
          background-color: white;
          color: blue;
        }
      }
    }

    &__calculate_validation_parent {
      display: flex;
      align-items: center;
      justify-content: center;
      &__error {
        position: relative;
        border-radius: 10px;
        width: max-content;
        top: -0.5rem;
        display: grid;
        place-content: center;
        align-items: center;
        padding: 0.3rem;
        grid-template-columns: 0.01fr 1fr;
        height: max-content;
        font-size: 14px;

        background-color: #ffd2d2;
        border: 1px solid #d8000c;

        button {
          width: 24px;
          height: 24px;
          background: #f13737;
          outline: none;
          border-radius: 50%;
          transform: rotate(45deg);
          margin-right: 10px;
          border: none;
          color: #000;
          font-size: 1.1rem;
          padding-left: 2px;
          padding-bottom: 3px;
        }
        p {
          color: #d8000c;
          font-size: 1.2em;
          font-weight: bold;
          text-align: left;
          margin-left: 9px;
        }
      }
    }
    .optional_filter_parent {
      margin-right: 5px;
      display: flex;
      flex-direction: row;
      border: 1px solid grey;
      height: 2.3rem;
      border-radius: 5px;
      padding: 10px 5px;
      align-items: center;

      position: relative;
      cursor: pointer;
      & > svg {
        font-size: 1.3rem;
      }
      &:hover {
        & > .filters_popover {
          display: flex;
        }
      }
      & > .filters_popover {
        position: absolute;
        display: none;
        width: max-content;

        flex-direction: column;
        background-color: white;
        box-shadow: 0 3px 10px rgb(14 14 14 / 50%);

        right: -2%;
        z-index: 9999;
        top: 102%;
        border-radius: 5px;

        & > div {
          cursor: pointer;
          display: flex;
          gap: 10px;
          justify-content: space-between;
          width: 100%;
          padding: 0.8rem 0.5rem;
          align-items: center;
          color: currentColor;
          & :first-child {
            align-items: center;
            display: flex;
            gap: 5px;
          }

          &:hover {
            background: $primary-blue-hover;
            color: $white;
            &:first-child,
            &:last-child {
              border-radius: 5px;
            }
          }
        }
      }
    }
    &__standrdRuleFilterParent {
      z-index: 2;
      margin-top: 0.5rem;
      display: flex;

      border-radius: 5px;
      width: 100%;
      border: 1px solid #ccc;
      align-items: center;
      & > .btn-filter_apply,
      .btn-filter_clear {
        width: 6rem;
        height: 2.3rem;
        margin: 5px;
        font-weight: bold;
      }
      & > .btn-filter_clear {
        background-color: $white;
        color: $primary-blue;
      }

      &__filters {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        border-radius: 5px;
        height: 3rem;
        width: 100%;
        align-items: center;
        padding: 2px;
        white-space: nowrap;

        .rateFilter {
          border: none;
          border-right: 1px solid $gray;
          border-radius: 0px;
          width: 110px;
        }

        & > .calcDatePicker {
          position: relative;
          flex: 1;

          border: none;
          border-right: 1px solid $gray;
          border-radius: 0px;

          & input::placeholder {
            font-size: 0.9rem !important;
          }

          & input:not(:empty) {
            &::after {
              position: absolute;
              content: "x";
              color: $gray;
            }
          }
        }
        & > .select {
          flex: 1;

          font-weight: normal;
          & .select__placeholder {
            font-size: 0.9rem !important;
          }

          & > .select__control {
            padding-left: 5px;
            border: none;
            border-right: 1px solid $gray;
            border-radius: 0px;

            & .select__indicator-separator {
              display: none;
            }
          }
        }
      }
    }
    &__deleteParent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      height: 100%;
      & div:first-child {
        font-size: 2rem;
      }
      & div:nth-child(2) {
        font-size: 1.2rem;
      }
      & div:nth-child(3) {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 2rem;
        & button {
          width: 5rem;
          height: 2.2rem;
          font-size: 0.9rem;
        }
      }
    }
    &__parentRuleconfirmation {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      gap: 1rem;
      & > span:first-child {
        font-size: 1.2rem;
        text-align: center;
        font-weight: normal;
        gap: 6px;
        display: flex;
        flex-direction: column;
      }
      & > span:nth-child(2) {
        font-size: 16px;
        text-align: center;
      }
      & > div {
        display: flex;
        gap: 4rem;
        & > button {
          width: max-content;
          padding: 0.4rem 0.5rem 0.4rem 0.5rem;
        }
      }
    }

    & > .qratesSectionContainer {
      padding-top: 1rem;
      min-height: max-content;

      & .tableMaxHeight {
        margin-top: 1rem;
        overflow: hidden;
        min-height: max-content;
        max-height: 48vh !important;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
      }
      .btn-addnewoptional {
        left: 91%;
        position: sticky;
        margin-bottom: 3px;
        cursor: pointer;
      }
      .th {
        .checkbox {
          display: flex;
          justify-content: space-around;
          width: 45%;
          & > input {
            cursor: pointer;
          }
          & > svg {
            & > rect {
              fill: $white;
              stroke: $primary-blue;
              cursor: pointer;
            }
            & > path {
              stroke: $white;
              cursor: pointer;
            }
          }
        }
      }

      .qrates_nodatafound_parent {
        height: max-content !important;
        display: grid;
        justify-content: center;
        & > .nodatafound {
          width: 6rem;

          position: relative;
          text-align: center;
          height: max-content;
          left: 46%;
          transform: translate(-50%);
          margin-top: 2rem;
        }
        & > .nodatafoundText {
          position: relative;

          text-align: center;
          height: max-content;
          left: 50%;
          transform: translate(-50%);
          opacity: 0.4;
          margin-bottom: 2rem;
        }
        & > .snodatafoundText {
          position: relative;
          top: 10px;
          text-align: center;
          height: max-content;
          left: 50%;
          transform: translate(-50%);
          opacity: 0.4;
        }
      }
      & > .standardruletoggleText {
        text-align: center;
        top: 0.5rem;
      }

      table {
        width: 100%;
        background-color: $primary-blue;
        font-size: 14px;
        margin: 0.5rem 0.5rem 0.5rem 0;
        thead {
          background-color: $primary-blue-hover;
          color: white;
          tr {
            text-align: left;
            th {
              padding: 0.5rem;
              width: calc((100% - 50%) / 3);
              height: 26px;
              &:nth-child(1) {
                background-color: $primary-blue-hover;
                border-radius: $br5;

                width: 150px;
                padding-left: 5px;
                text-align: left;
                min-width: 150px;
                max-width: 150px;

                border-right: 1px solid $primary-blue;
              }
              &:nth-child(2) {
                background-color: $primary-blue-hover;
                border-radius: $br5;

                width: 150px;
                min-width: 150px;
                max-width: 150px;

                border-right: 1px solid $primary-blue;
              }
              &:nth-child(3) {
                background-color: $primary-blue-hover;
                border-radius: $br5;

                width: 200px;
                min-width: 200px;
                max-width: 200px;

                outline: 2px solid $primary-blue;
              }
              &:nth-child(n + 3) {
                width: 150px;
                min-width: 150px;
                max-width: 150px;
              }
            }
          }
        }
        tbody {
          tr {
            &.hide {
              display: none;
            }
            td {
              padding: 0.5rem;
              text-align: left;
              font-weight: normal;
              &:nth-child(1) {
                width: 150px;
                padding-left: 5px;
                text-align: left;
                min-width: 150px;
                max-width: 150px;

                background: white;
                outline: 2px solid $primary-blue;
              }
              &:nth-child(2) {
                width: 150px;
                min-width: 150px;
                max-width: 150px;

                background: white;
                outline: 2px solid $primary-blue;
              }
              &:nth-child(3) {
                width: 200px;
                min-width: 200px;
                max-width: 200px;

                background: white;
                outline: 2px solid $primary-blue;
              }
              &:nth-child(n + 3) {
                width: 150px;
                min-width: 150px;
                max-width: 150px;
              }
            }
            background-color: $white;
          }
        }
      }
    }

    & > .toggleSectionContainerHeight {
      padding-top: 1rem;
      height: 17vh !important;
      min-height: 17vh !important;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;

      & > .tableMaxHeight {
        height: 0vh !important;
        min-height: 0vh !important;
      }
    }
    &__businessModal {
      height: 100%;
      width: 100%;
      overflow: hidden;
      & > header {
        display: grid;

        grid-template-columns: repeat(7, 1fr);
        gap: 1rem;
        border-bottom: 1px solid $primary-blue;
        padding: 1rem;
        place-items: center;
        & > div {
          display: flex;
          gap: 1rem;
          grid-column-start: 8;
          align-items: center;
        }

        & > h3 {
          color: $white;
          color: $primary-blue;
          grid-column-start: 4;
          width: max-content;
          text-align: center;
        }
        & button {
          outline: none;
          border: none;
          cursor: pointer;
          font-size: 1.2rem;
          background: none;
          color: white;
          color: $primary-blue;
          font-weight: bold;
        }
        & svg {
          fill: white;
          fill: $primary-blue;
          cursor: pointer;
        }
      }

      &__bodyParent {
        padding: 1rem 3rem 0 3rem;
        height: 83%;
        overflow-y: auto;
      }
      .btn-editButtonStyle {
        position: relative;
        left: 45%;
        top: 1rem;
      }
      .btn-cancelButtonStyle {
        position: relative;
        left: 40%;
        top: 1rem;
      }
    }
    &__clientListParent {
      position: relative;
      z-index: 5;
      display: grid;
      justify-items: center;
      align-items: center;
      margin: 2rem 1rem 2rem 0px;
      gap: 5px;
      grid-template-columns: 1fr;

      & > span {
        display: none;
      }

      & .select .select__control {
        border: none;
        height: 3rem;
        padding-left: 5px;
        &::before {
          content: "";
          background: url("../Icon/Icons/searchIcons.svg");
          width: 1rem;
          height: 1rem;
          margin-left: 6px;
          background-repeat: no-repeat;
        }

        & .select__indicator-separator {
          display: none;
        }
        & .select__clear-indicator {
          & svg {
            display: none;
          }
          &::after {
            content: "clear";
            cursor: pointer;
          }
        }
      }

      &__clientList {
        &:hover {
          & ~ span {
            display: block;
            width: max-content;
            padding: 0.5rem 1rem 0.5rem 1rem;
            height: max-content;
            position: absolute;
            top: 4rem;
            background: white;
            box-shadow: 0 3px 10px rgb(14 14 14 / 20%);
            border-radius: 5px;
            z-index: 100;
            font-size: 12px;
            &::before {
              content: "";
              background: url("../Icon/Icons/triangle.svg");
              width: 1rem;
              height: 1rem;
              background-repeat: no-repeat;
              position: absolute;
              left: 41%;
              bottom: 78%;
            }
          }
        }
        width: 32%;
        transition: width 0.5s;
        &:focus-within {
          width: 50%;
          transition: width 0.5s;
        }
        box-shadow: 0 3px 10px rgb(14 14 14 / 20%);
        border-radius: 10px;
        position: relative;
        padding: 4px;
        border: 1px solid #ccc;
        & .select__dropdown-indicator {
          display: none;
        }
      }

      &__clientListlabel {
        height: 100%;
      }
    }
    &__clientListopacity {
      z-index: 1;
    }
  }
}
