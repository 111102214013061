.workOrder {
  width: 100%;

  .workOrder {
    &__header {
      display: flex;
      gap: 24px;
      align-items: center;
      justify-content: flex-start;

      &__title {
        margin: 0;
        text-decoration: underline;
        font-size: 1.2rem;
      }

      .btn-workOrder {
        width: 100px;
      }
    }

    &__body {
      &__row {
        display: flex;
        padding: 16px 0px 16px 0px;
        gap: 12px;
        font-weight: normal;
        .label {
          margin: 0;
        }
      }
      &__disableAccess {
        pointer-events: none;
        opacity: 0.6;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-button {
        background: #eee;
      }
      &::-webkit-scrollbar-track-piece {
        background: #eee;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(165, 165, 165);
      }
    }
  }
}
