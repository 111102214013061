.popup_blur {
  z-index: 9000;
  pointer-events: none;
  cursor: not-allowed;
  position: absolute;
  user-select: none;
  filter: blur(10px);
  background-color: #ffffffb0;
  width: 100%;
  height: 100%;
}

.blocker {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1; /* Place the blocker above other elements */
  pointer-events: auto; /* Enable pointer events on the blocker */
  background-color: #ffffffb0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2; /* Place the overlay above the blocker */
  pointer-events: none; /* Disable pointer events on the overlay */
  /* Additional styling for the overlay if needed */
}

.rules_edit_popup {
  width: 40em;
  justify-content: center;
  z-index: 9000;
  user-select: none;
  height: 20em;
  background-color: #f5f3f7;
  position: fixed;
  box-shadow: 0px 16px 24px rgba(161, 153, 194, 0.24),
    0px 12px 20px rgba(161, 153, 194, 0.24),
    0px 8px 16px rgba(161, 153, 194, 0.2), 0px 4px 8px rgba(161, 153, 194, 0.16),
    0px 2px 4px rgba(161, 153, 194, 0.12), 0px 1px 2px rgba(161, 153, 194, 0.08);
  border-radius: 8px;
  margin: auto;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  align-items: center;
  justify-self: center;
}

.form_container {
  width: 100%;

  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.aligning_container {
  width: 82%;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
}
.client_rules_aligning_container {
  width: 90%;
  gap: 2rem;
}

.header {
  margin: 0;
  color: #352281;
  font-style: normal;
  font-family: "Museo Slab";
  font-style: normal;
  font-weight: 300;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.sub_header {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #575463;
}

.elements_position {
  display: flex;
  display: flex;
  grid-gap: 2rem;
  width: 100%;
}

.alter_location_width {
  width: 100%;
}

.location_styles {
  display: flex;
  gap: 1rem;
  & > div {
    &:first-child {
      flex: 1;
    }
    &:last-child {
      flex: 0.4;
      margin-right: 4px;
    }
  }
}

.btn_update_styling {
  cursor: pointer;
  width: max-content;
  font-weight: 500;
  height: 40px;
  background-color: #1559ea;
  color: white;
  &:hover {
    background-color: #1559ea;
  }
  &:disabled {
    background-color: #b9b8b8;
    color: #918f8f;
  }
}

.btn_calcel_styling {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  color: black;
  background-color: #f5f3f7;
  border: none;
  &:hover {
    color: black;
    background-color: #f5f3f7;
  }
}
.btn_position {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}
.alter_date_styles {
  background: white;
  border: none;
  border-radius: 1px;
  border-bottom: 1px solid;
  height: 44px;
  width: 99%;
}

.form_error_style {
  border-bottom: 2px solid rgb(185, 34, 34);
}

.align_client_list {
  display: flex;
  gap: 10px;
  height: 8rem;
  flex-flow: wrap;
  overflow-y: scroll;
}

.client_card_styling {
  width: max-content;
  height: max-content;
  padding: 0.5rem;
  background-color: #352281bf;
  color: white;
  font-weight: bold;
  border-radius: 5px;
}

.loader_styling {
  width: 100%;
}
.loader_styling {
  position: absolute;
  height: inherit;
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff85;
  z-index: 1;
  font-weight: bold;
  font-size: 1.5rem;
}
