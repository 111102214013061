/* Custom styles for Datepicker */
.date-dropdown .react-datepicker-wrapper {
  display: inline-block;
}

.date-dropdown .react-datepicker {
  border: none;
}
.date-dropdown .react-datepicker__header {
  background-color: #fff;
}

.date-dropdown .react-datepicker__input-container input {
  width: 200px;
  height: 36px;
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.date-dropdown .react-datepicker__input-container input::placeholder {
  color: #999;
}

.date-dropdown .react-datepicker__input-container input:focus {
  outline: none;
  border-color: #007bff;
}
