@import "../../styles/variables.scss";
.switch {
  width: 76px;
  height: 26px;
  grid-column-start: 2;
  grid-column-end: 3;
  cursor: pointer;
}

.toggle {
  position: absolute;
  border: 1px solid $primary-blue;
  border-radius: 5px;
  -webkit-transition: border-color 0.6s ease-out;
  transition: border-color 0.6s ease-out;
  box-sizing: border-box;
}

.toggle.toggle-on {
  border-color: $primary-blue;
  -webkit-transition: all 0.5s 0.15s ease-out;
  transition: all 0.5s 0.15s ease-out;
}

.toggle-button {
  position: absolute;
  top: 1.5px;
  width: 21px;
  height: 1.3rem;
  bottom: 16px;
  right: 51px;
  background-color: $primary-blue;
  border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.3s 0.1s, width 0.1s, top 0.1s, bottom 0.1s;
  transition: all 0.3s 0.1s, width 0.1s, top 0.1s, bottom 0.1s;
}

.toggle-on .toggle-button {
  top: 1.5px;
  width: 21px;
  bottom: 3px;
  right: 1px;
  height: 1.3rem;
  border-radius: 5px;
  background-color: $primary-blue;
  box-shadow: 0 0 0px #4b7a8d;
  -webkit-transition: all 0.2s 0.1s, right 0.1s;
  transition: all 0.2s 0.1s, right 0.1s;
  color: $white;
}

.toggle-text-on {
  position: absolute;
  top: -5px;
  bottom: 0px;
  left: -16px;
  right: 0px;
  line-height: 36px;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome/Safari */

  color: $primary-blue;
}

.toggle-on .toggle-text-on {
  color: $primary-blue;
  -webkit-transition: color 0.3s 0.15s;
  transition: color 0.3s 0.15s;
}

.toggle-text-off {
  position: absolute;
  top: -6px;
  bottom: 0;
  right: 5px;
  line-height: 36px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  -webkit-user-select: none; /* Chrome/Safari */

  cursor: pointer;

  color: #444249;
}

.toggle-on .toggle-text-off {
  color: rgba(0, 0, 0, 0);
}

/* used for streak effect */
.glow-comp {
  position: absolute;
  opacity: 0;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  border-radius: 5px;
  background-color: rgba(75, 122, 141, 0.1);
  box-shadow: 0 0 12px rgba(75, 122, 141, 0.2);
  -webkit-transition: opacity 4.5s 2s;
  transition: opacity 4.5s 2s;
}

.toggle-on .glow-comp {
  opacity: 1;
  -webkit-transition: opacity 2s;
  transition: opacity 2s;
}
