.ruleset_filter {
  height: 52px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 2.5rem;
  justify-content: space-between;
}
.filters {
  display: flex;
  gap: 1rem;
  font-size: 0.7rem;
  & > div {
    &:first-child {
      gap: 5px;
      padding-right: 9px;
      border-right: 1px solid #dadadc;
    }

    display: flex;
    align-items: center;
  }
}

.filter_border {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.alter_date_picker_style {
  border: none;
  width: 9rem;
  display: flex;
  align-items: center;
  border-right: 1px solid #dadadc;
  & > div:first-child {
    border-bottom: none;
    & > div {
      button {
        top: -1px;
      }
      & > div {
        background-color: transparent !important;
        padding-top: 0 !important;
      }
    }
  }
}
