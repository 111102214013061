.mandatory {
  top: 5px;
  font-size: 12px;
  color: rgb(185, 34, 34);
  margin-left: 0.5rem;
}
.parent {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 48%;
  justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.rate_low,
.rate_high {
  outline: none;
  font-size: 0.8rem;
  color: #575463;
  padding-left: 0.5rem;
  width: 100%;
  height: 100%;
  border: none;
  text-overflow: ellipsis;

  [placeholder] {
    text-overflow: ellipsis;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    text-overflow: ellipsis;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    text-overflow: ellipsis;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    text-overflow: ellipsis;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    text-overflow: ellipsis;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  padding-left: 5px;
}
.rate_selected {
  background-color: rgba(104, 85, 180, 0.08) !important;
  border: 1px solid rgba(104, 85, 180, 0.08) !important;
  border-bottom: 2px solid !important;
  border-radius: 0px;
  padding-top: 10px;
}

.selected_fill {
  border-bottom: 2px solid !important;
  border-radius: 0px;
}

.currency_drp {
  height: 2.5rem;
  background-color: rgba(104, 85, 180, 0.08) !important;
  border: 1px solid rgba(104, 85, 180, 0.08) !important;
  color: #352281;
  & > option {
    border: 1px solid;
  }
  border-bottom: 2px solid !important;
}

.rateLow_label_with_data,
.rateHigh_label_with_data {
  position: absolute;
  font-size: 0.6rem;
  top: 4px;
  left: 7px;
  text-overflow: ellipsis;
}

.rateLow_parent,
.rateHigh_parent {
  width: 45%;
  height: 2.5rem;
}
