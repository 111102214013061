.container {
  background-color: #fff;
  box-shadow: 0px -1px 2px 0px rgba(161, 153, 194, 0.08),
    0px -2px 4px 0px rgba(161, 153, 194, 0.12),
    0px -4px 8px 0px rgba(161, 153, 194, 0.16);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  width: 100%;

  & > .btn_calcel_styling {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    cursor: pointer;
    color: #352281;
    font-weight: 500;
    font-size: 16px;
    background: none;
    border: none;
    gap: 6px;
    &:hover {
      color: #352281;
      background: none;
    }
  }

  & > .btn_update_styling {
    width: max-content;
    cursor: pointer;
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    background-color: #1559ea;
    color: white;
    border: none;
    &:hover:not(:active, :disabled) {
      background: #3270eb;
      box-shadow: 0px 1px 2px 0px rgba(21, 89, 234, 0.08),
        0px 2px 4px 0px rgba(21, 89, 234, 0.12),
        0px 4px 8px 0px rgba(21, 89, 234, 0.16),
        0px 8px 16px 0px rgba(21, 89, 234, 0.2);
      backdrop-filter: blur(100px);
    }
    &:active {
      background: #1046b8;
    }
    &:disabled {
      background: rgba(21, 89, 234, 0.25);
      color: white;
    }
  }

  & > *:not(:first-child) {
    margin-left: 32px;
  }
}
