.ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.table_accordion {
  width: 94.5%;
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;
  background-color: #eae9f2;
  align-items: center;
  &:hover {
    .scroll_left,
    .scroll_right {
      display: block;
    }
  }
}
.group {
  background-color: #eae9f2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 10rem;
  max-height: 17rem;
  overflow-x: auto;
  overflow-y: hidden;
  width: 98%;
  position: relative;
}

.group li {
  clear: both;
}
.group div {
  text-align: center;
  font-size: 14px;
}
.group2 ul {
  display: table;
  width: 100%;
  margin-top: 1rem;
}
.group2 li {
  padding: 0.5rem;
  margin: 5px;
  display: flex;
  flex-direction: row;
}
.group2 div {
  display: table-cell;
  vertical-align: middle;
  width: max-content;
  text-align: left;
  padding: 2px 6px;
  flex: 1;
  position: relative;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
    & > span {
      font-family: "Roboto";
      width: max-content;
      &:first-child {
        font-size: 10px;
        color: #575463;
      }
      &:last-child {
        font-size: 12px;

        color: #100a27;
      }
    }
  }
}
.group-two_ul_width_for_min_data {
  width: max-content;
}
.group_tow_p_wdith_for_min_data {
  width: 100%;
}

.group2_li_min_width_display {
  display: table-row !important;
}

.row_bgcolor {
  &:nth-child(even) {
    background-color: rgba(213, 247, 255, 0.2);
  }
  &:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.cell_divider {
  position: absolute;
  width: 2px;
  height: 86%;
  top: 6%;
  left: 100%;
  background-color: rgba(161, 153, 194, 0.15);
  opacity: 0.3;
}

.table_header {
  width: 100%;
  background-color: #007f9c;
  color: white;
  padding: 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 32px;
  & > svg {
    cursor: pointer;
  }
  .header_first_section {
    gap: 10px;
    display: flex;
    align-items: center;
    & > span {
      &:first-child {
        font-size: 14px;
        font-weight: 500;
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
        cursor: pointer;
        & > svg {
          color: rgba(255, 255, 255, 0.6);
        }
        & > p {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          font-weight: 400;
        }
      }
    }
  }
}

.edit_row_cell {
  background-color: white;
  box-shadow: 0px 5px 10px 8px #eae9f2;
  &:hover {
    box-shadow: 0px 5px 32px 8px #eae9f2;
  }
  position: sticky !important;
  right: 0;
  border-left: 1px solid rgba(161, 153, 194, 0.15);
  border-bottom: 1px solid rgba(161, 153, 194, 0.15);
  width: 2.5rem !important;
  z-index: 1;
  position: relative;
  & > div {
    align-items: center;
  }
}

.edit_row_support_cell {
  background-color: #eae9f2;
  position: sticky !important;
  right: 0;
  width: 24px !important;
  & > div {
    & svg {
      cursor: pointer;
      font-size: 1.3rem;
    }
  }
}
.scroll_left,
.scroll_right {
  display: none;
  position: absolute;
  height: max-content;
  z-index: 1;
  width: max-content;
  top: 40%;
  & > svg {
    border-radius: 1rem;
    background-color: white;
    width: 2rem;
    height: 2rem;
  }
}
.scroll_top_position_min_width {
  top: 45%;
}
.scroll_top_position_max_width {
  top: 45%;
}
.scroll_right {
  left: 88%;

  box-shadow: 9rem 2rem 42rem 2rem #eae9f3;
}
.scroll_left {
  left: 5%;

  box-shadow: -26rem 0px 18rem 12rem #eae9f3;
}

.see_all_results {
  margin: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  & > svg {
    font-size: 1.2rem !important;
  }
}

.skeliton_styling {
  width: 100vw;
  height: 30px;
  margin-bottom: 4px;
}

.table_accordion_collapse {
  height: 0;
  min-height: 0;
  max-height: 0;
}
.table_header_border {
  border-radius: 5px;
}

.show_full_data {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

.table_footer {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 1.5rem;
  padding: 0;
  height: 3rem;
}
.empty_error_page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 21%;
  vertical-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  width: 50%;
  height: 21%;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  opacity: 0.5;
  align-items: center;
  gap: 0.5rem;
}

.empty_data_icon {
  font-size: 3rem;
}

.settings_table {
  display: flex !important;
  flex-direction: row !important;
  gap: 2rem !important;
  position: relative !important;
  left: 1rem !important;
  height: 32px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  & > hr {
    height: 100%;
    background-color: rgba(161, 153, 194, 0.15);
    width: 1px;
    opacity: 0.4;
  }
}

.disable_payment {
  pointer-events: none;
  opacity: 0.4;
}

.estimate_payment {
  cursor: pointer;
  font: Roboto;
  font-size: 12px;
  color: #352281;
}

.alter_toggle_switch {
  width: 30px;
  height: 17px;
}

.alter_toggle_slider {
  &::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 2px;
    bottom: 1.3px;
    transition: 0.4s;
  }
}

.comment_cellwidth {
  width: 4rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  clear: both;
  position: relative;

  &:hover {
    .comment_tooltip {
      display: block;
      position: absolute;
      background-color: white;
      width: 100px;
      height: 100px;
    }
  }
}

.comment_cellwidth_for_ruleset_desc {
  width: 32rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  clear: both;
  position: relative;
}
.comment_tooltip {
  display: none;
}

.comment_overlay {
  width: 100% !important;
  height: 100%;
  position: absolute !important;
}

.comment_cellwidth {
  width: 4rem !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  clear: both;
  position: relative;
}

.tooltip_styling {
  background-color: white;
  width: inherit !important;
  height: inherit !important;
  font-size: 0.9rem !important;
  line-height: 25px !important;
  resize: none;
  border: none;
  padding: 0.5rem;
  padding-top: 0.8rem;
}
.altr_tooltip_styling {
  width: max-content !important;
  height: max-content !important;
}
.tooltip_content_parent {
  background-color: white;

  width: 27rem !important;
  border-radius: 5px;

  height: 10rem !important;
  overflow-y: scroll;

  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-bottom: 6px solid #150b3b;
  position: relative;
}

.altr_tooltip_dimentions {
  max-width: 10rem !important;
  max-height: 4rem !important;
  z-index: 100;
}
.tooltip_arrow {
  position: relative;
  height: 1rem;
  z-index: 0;
  &::after {
    position: absolute;
    content: "";
    width: 10px;
    left: 4rem;
    top: -20%;
    height: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(145deg);
    z-index: 0;
    background-color: #150b3b;
  }
}

.tippy-box {
  background-color: tomato !important;
  color: yellow !important;
}

.tippy-content {
  color: green;
}

[data-theme~="custom"] {
  background-color: white !important;
  color: #150b3b;

  border-radius: 5px !important;
  max-height: 10rem;
  overflow-x: hidden;
  overflow-y: scroll;
  border-bottom: 5px solid #150b3b !important;
  box-shadow: 0rem -0.1rem 7rem 0rem rgba(213, 247, 255, 0.5);

  & div:first-child {
    font-size: 0.8rem;
    padding: 1rem;
    white-space: break-spaces;
    overflow-wrap: break-word;
  }
  & div:nth-child(2) {
    display: none;
  }
}

.alter_popover_style {
  width: max-content;

  height: max-content;
  background: white;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  padding: 0.8rem 0.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  border-radius: 5px;
  box-shadow: 1px 2px 16px rgb(33 20 81);
}
.alter_popover_button_style {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}
