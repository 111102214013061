.input {
  width: 20rem;
  height: 2.5rem;
  border: none;
  border-bottom: 2px solid;
  outline: none;
  color: #575463;
  padding-left: 0.4rem;
  font-size: 0.9rem;
}

.input_selected {
  width: 20rem;
  height: 2.5rem;
  border: none;
  border-bottom: 2px solid;
  outline: none;
  color: #575463;
  padding-left: 0.4rem;
  padding-top: 10px;
  font-size: 0.9rem;
}

.mandatory {
  top: 44px;
  font-size: 11px;
  color: rgb(185, 34, 34);
  margin-left: 0.5rem;
  position: absolute;
}
.parent {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.selected_fill {
  background-color: rgb(228 223 240) !important;
  border: 1px solid rgba(104, 85, 180, 0.08) !important;
  border-bottom: 2px solid !important;
  border-radius: 0px;
}
.label_with_data {
  position: absolute;
  z-index: 1;
  font-size: 10px;
  color: #352281;
  top: 3px;
  opacity: 0.7;
  padding-left: 0.5rem;
}
