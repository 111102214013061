.aligning_container {
  width: 95%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 55%;
  position: relative;

  margin: 0 2rem;

  gap: 2rem;
}

.header {
  padding-top: 2rem;
  color: #352281;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  top: 2rem;
}
.description_width {
  width: 50% !important;
  margin-bottom: 19px;
  height: 12rem;
}

.name_width {
  width: 50% !important;
}

.alter_desctiption_mandatory {
  top: 12.5rem;

  font-size: 12px;
  color: rgb(185, 34, 34);
  margin-left: 0.5rem;
  position: absolute;
}
.icon_close_positon {
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.btn_position {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 3rem;
  padding-left: 2.5rem;
  bottom: 2rem;
}
.btn_create {
  height: 3rem;
  width: 7.5rem;
  font-size: 16px;
}
.btn_calcel_styling {
  height: 3rem;
  width: 7.5rem;
  cursor: pointer;
  color: black;
  font-size: 16px;
  font-weight: 500;
  background-color: #f5f3f7;
  border: none;
  &:hover {
    color: black;
    background-color: #f5f3f7;
  }
}
.form_error_style {
  border-bottom: 2px solid rgb(185, 34, 34);
}
.rules_error_while_mapping {
  font-size: 1.2rem;
  color: red;
}
.aligning_container {
  width: inherit;
  height: inherit;
}
