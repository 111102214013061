.main_body_styling {
  background-color: rgb(245, 243, 247);
  height: calc(100vh - 63px);
  width: clamp(50rem, 100vw, 95rem);
}
.main_parent {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
