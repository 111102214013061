.header_section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  width: 305px;
  gap: 10px;
}

.markup_header {
  display: flex;
  justify-content: center;
  width: 152px;
  gap: 10px;
}

.tab {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
  white-space: nowrap;
  color: #575463;
}
.active_tab {
  color: #100a27;
}

.select_notifier {
  position: absolute;
  top: 38px;
  left: 0;
  height: 3px;
  width: 152px;
  background-color: #009ca6;
  transition: transform 0.3s;
  border-radius: 2px 2px 0 0;
}

.disable_simulate {
  pointer-events: none;
  background-color: #b8b3bc;
}

.simulate_disabled {
  > div {
    pointer-events: none;
    color: #b8b3bc;
  }
}
.adjust_tab_alignment {
  padding-left: 9rem;
}
