.btn_create {
  width: max-content;
  height: 32px;
  margin: 1rem;
}
.ruleset_table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  background-color: rgba(234, 233, 242, 1);
}

.btn_create_parent {
  width: inherit;
  padding: 0 2rem;
  display: flex;
  justify-content: end;
}
.edit_popover_styling.edit_popover_styling_helper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  &:hover {
    background-color: rgb(222, 220, 228);
  }
  width: 100%;
  border-radius: 5px;
  z-index: 1;
  padding: 10px;
  cursor: pointer;
}
.blocker {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1; /* Place the blocker above other elements */
  pointer-events: auto; /* Enable pointer events on the blocker */
  background-color: #ffffffb0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2; /* Place the overlay above the blocker */
  pointer-events: none; /* Disable pointer events on the overlay */
  /* Additional styling for the overlay if needed */
}
.rules_set_create_popup {
  gap: 1rem;
  width: 70em;
  z-index: 9000;
  overflow-y: auto;
  align-items: start;
  user-select: none;
  height: 35em;
  background-color: #f5f3f7;
  position: fixed;
  box-shadow: 0px 16px 24px rgba(161, 153, 194, 0.24),
    0px 12px 20px rgba(161, 153, 194, 0.24),
    0px 8px 16px rgba(161, 153, 194, 0.2), 0px 4px 8px rgba(161, 153, 194, 0.16),
    0px 2px 4px rgba(161, 153, 194, 0.12), 0px 1px 2px rgba(161, 153, 194, 0.08);
  border-radius: 8px;
  margin: auto;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  justify-self: center;
}

.aligning_container {
  width: 95%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 55%;
  position: relative;

  margin: 0 2rem;

  gap: 2rem;
  top: 3rem;
}

.description_width {
  width: 50% !important;
  margin-bottom: 19px;
  height: 12rem;
}

.name_width {
  width: 50% !important;
}

.alter_desctiption_mandatory {
  top: 12.5rem;

  font-size: 12px;
  color: rgb(185, 34, 34);
  margin-left: 0.5rem;
  position: absolute;
}
.icon_close_positon {
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.btn_position {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-right: 3rem;
  bottom: 0rem;
}
.btn_create {
  height: 2rem;
  font-size: 16px;
}
.btn_calcel_styling {
  height: 3rem;
  width: 7.5rem;
  cursor: pointer;
  color: black;
  font-size: 16px;
  font-weight: 500;
  background-color: #f5f3f7;
  border: none;
  &:hover {
    color: black;
    background-color: #f5f3f7;
  }
}
.form_error_style {
  border-bottom: 2px solid rgb(185, 34, 34);
}
.header {
  padding-top: 2rem;
  color: #352281;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  top: 2rem;
}

.icon_close_positon {
  margin-left: 2rem;
}
.checkBox_styling {
  width: 25rem;
  padding-right: 26px;
  cursor: pointer;
  & > svg {
    width: 22px;
    height: 22px;
  }
}

.apply_ruleset_popup {
  width: 85em;
  z-index: 9000;
  user-select: none;
  height: 35em;
  background-color: #f5f3f7;
  position: fixed;
  box-shadow: 0px 16px 24px rgba(161, 153, 194, 0.24),
    0px 12px 20px rgba(161, 153, 194, 0.24),
    0px 8px 16px rgba(161, 153, 194, 0.2), 0px 4px 8px rgba(161, 153, 194, 0.16),
    0px 2px 4px rgba(161, 153, 194, 0.12), 0px 1px 2px rgba(161, 153, 194, 0.08);
  border-radius: 8px;
  margin: auto;
  display: flex;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-self: center;
}

.ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.table_accordion {
  width: inherit;
  padding: 0 2rem;
  height: calc(100% - 3.9rem);
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  &:hover {
    .scroll_left,
    .scroll_right {
      display: block;
    }
  }
}
.group {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  width: 98%;
  position: relative;
  margin-top: 10px;
}

.group li {
  clear: both;
}
.group div {
  text-align: center;
  font-size: 14px;
}
.group2 ul {
  display: table;
  width: 100%;
}
.group2 li {
  padding: 0.5rem;
  margin: 5px;
  display: flex;
  flex-direction: row;
  & > div {
    padding: 0.5rem;
  }
  & > div:first-child {
    background-color: white;
    border-bottom: 1px solid #76767630;
    & > div {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      clear: both;
      overflow: hidden;
    }
    .cell_divider {
      display: none;
    }
    width: 3rem;
  }

  & > div:nth-child(2) {
    & > div {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      clear: both;
      overflow: hidden;
    }
    .cell_divider {
      display: none;
    }
    width: 56rem;
  }
  & > div:nth-child(3) {
    & > div {
      width: 25rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      clear: both;
      overflow: hidden;
    }
    .cell_divider {
      display: none;
    }
    width: 20rem;
  }
  & > div:nth-child(4) {
    display: flex;
    justify-content: end;
    width: 100%;
    .cell_divider {
      display: none;
    }
    & > div {
      text-align: right;
      border-right: none;
      padding-top: 11px;
    }
  }
}
.createdon_parent {
  color: rgba(53, 34, 129, 1);

  & > b {
    font-size: 1rem;
    font-weight: 500;
    color: rgba(53, 34, 129, 1);
  }
}
.createdon_header {
  font-size: 0.5rem;
  margin-top: 4px;
  & > :nth-child(2) {
    color: rgba(53, 34, 129, 1) !important;
    font-size: 0.7rem !important;
  }
}
.group2 div {
  display: table-cell;
  vertical-align: middle;
  width: max-content;
  text-align: left;
  padding: 2px 6px;
  flex: 1;
  position: relative;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 3px;
    & > span {
      font-family: "Roboto";
      width: 100%;
      &:first-child {
        font-size: 10px;
        color: #575463;
      }
      &:last-child {
        font-size: 12px;

        color: #100a27;
      }
    }
  }
}
.group-two_ul_width_for_min_data {
  width: max-content;
}
.group_tow_p_wdith_for_min_data {
  width: 100%;
}

.group2_li_min_width_display {
  display: table-row !important;
}

.row_bgcolor {
  &:nth-child(even) {
    background-color: rgba(213, 247, 255, 0.2);
  }
  &:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.6);
  }
  cursor: pointer;
}

.cell_divider {
  position: absolute;
  width: 2px;
  height: 86%;
  top: 6%;
  left: 100%;
  background-color: rgba(161, 153, 194, 0.15);
  opacity: 0.3;
}

.table_header {
  width: 100%;
  color: #100a27;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 32px;
  padding-left: 1rem;
  padding-top: 1rem;
  & > svg {
    cursor: pointer;
  }
  .header_first_section {
    gap: 10px;
    display: flex;
    align-items: center;
    & > span {
      &:first-child {
        font-size: 16px;
        font-weight: 500;
      }
      &:nth-child(2) {
        display: flex;
        align-items: center;
        cursor: pointer;
        & > svg {
          color: rgba(255, 255, 255, 0.6);
        }
        & > p {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
          font-weight: 400;
        }
      }
    }
  }
}

.edit_row_cell {
  align-items: center;

  border-left: 1px solid rgba(161, 153, 194, 0.15);
  border-bottom: 1px solid rgba(161, 153, 194, 0.15);
  width: 3rem !important;
  height: 37px;
  display: flex;
  z-index: 1;
  position: relative;
  & > div {
    align-items: center;
  }

  background-color: rgb(247, 246, 249, 0.98);
}
.toggle_position {
  position: sticky !important;
  right: 0;
  z-index: 1;
}
.edit_row_position {
  position: sticky !important;
  right: 0;
  border-left: none;
  &:nth-child(even) {
    background-color: rgba(213, 247, 255, 0.1);
  }
  &:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.6);
  }
}
.manage_icons {
  display: flex !important;
  flex-direction: row !important;
  gap: 1rem !important;
}

.edit_row_support_cell {
  background-color: #eae9f2;
  position: sticky !important;
  right: 0;
  width: 24px !important;
  & > div {
    & svg {
      cursor: pointer;
      font-size: 1.3rem;
    }
  }
}
.scroll_left,
.scroll_right {
  display: none;
  position: absolute;
  height: max-content;
  z-index: 1;
  width: max-content;
  top: 40%;
  & > svg {
    border-radius: 1rem;
    background-color: white;
    width: 2rem;
    height: 2rem;
  }
}
.scroll_top_position_min_width {
  top: 45%;
}
.scroll_top_position_max_width {
  top: 45%;
}
.scroll_right {
  left: 88%;

  box-shadow: 9rem 2rem 42rem 2rem #eae9f3;
}
.scroll_left {
  left: 5%;

  box-shadow: -26rem 0px 18rem 12rem #eae9f3;
}

.see_all_results {
  margin: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  & > svg {
    font-size: 1.2rem !important;
  }
}

.skeliton_styling {
  width: 100vw;
  height: 30px;
  margin-bottom: 4px;
}

.table_accordion_collapse {
  height: 0;
  min-height: 0;
  max-height: 0;
}
.table_header_border {
  border-radius: 5px;
}

.show_full_data {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

.table_footer {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 1.5rem;
  padding: 0;
  height: 3rem;
}
.empty_error_page {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 21%;
  vertical-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  width: 50%;
  height: 21%;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  opacity: 0.5;
  align-items: center;
  gap: 0.5rem;
}

.empty_data_icon {
  font-size: 2rem;
}

.settings_table {
  display: flex !important;
  flex-direction: row !important;
  gap: 2rem !important;
  position: relative !important;
  left: 1rem !important;
  height: 32px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  & > hr {
    height: 100%;
    background-color: rgba(161, 153, 194, 0.15);
    width: 1px;
    opacity: 0.4;
  }
}

.disable_payment {
  pointer-events: none;
  opacity: 0.4;
}

.estimate_payment {
  cursor: pointer;
  font: Roboto;
  font-size: 12px;
  color: #352281;
}

.alter_toggle_switch {
  width: 30px;
  height: 17px;
}

.alter_toggle_slider {
  box-shadow: none;
  &::before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 2px;
    bottom: 1.3px;
    transition: 0.4s;
  }
}

.alter_button_style {
  display: flex;
  color: #130d29;
  border-radius: 5px;
  border: none;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  padding: 4px 16px;
  cursor: pointer;
  background-color: #eae9f3;
  height: 1.5rem;
  &:hover {
    background: none;
    color: rgb(62, 48, 116);
  }
  &:disabled {
    background: none;
    user-select: none;
    color: currentColor;
  }
}

.alter_search_style {
  height: 36px;
  top: -2px;
  left: -0.7rem;
  width: 289px;
  border-radius: 50px;
  background-color: rgba(161, 153, 194, 0.15);
  padding: 12px 20px;
  border: none;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(161, 153, 194, 0.15);
  }
  & > input {
    background: none;
    color: #352281;
    &::placeholder {
      color: #352281;
    }
    & > .search {
      fill: #352281;
    }
  }
}

.comment_cellwidth {
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  clear: both;
  position: relative;
}

.tooltip_styling {
  background-color: white;
  width: inherit !important;
  height: inherit !important;
  font-size: 0.9rem !important;
  line-height: 25px !important;
  resize: none;
  border: none;
  padding: 0.5rem;
  padding-top: 0.8rem;
}
.altr_tooltip_styling {
  width: max-content !important;
  height: max-content !important;
}
.tooltip_content_parent {
  background-color: white;

  width: 27rem !important;
  border-radius: 5px;

  height: 10rem !important;
  overflow-y: scroll;

  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-bottom: 6px solid #150b3b;
  position: relative;
}

.altr_tooltip_dimentions {
  max-width: 10rem !important;
  max-height: 4rem !important;
  z-index: 100;
}
.tooltip_arrow {
  position: relative;
  height: 1rem;
  z-index: 0;
  &::after {
    position: absolute;
    content: "";
    width: 10px;
    left: 4rem;
    top: -20%;
    height: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(145deg);
    z-index: 0;
    background-color: #150b3b;
  }
}

.align_radio_btn {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  height: 100% !important;
  & > div {
    input {
      cursor: pointer;
      width: 1.7rem;
      height: 2.2rem;
      accent-color: #150b3b;
    }
  }
  & > svg {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
}
.selected_ruleset {
  background-color: #a199c280 !important;
  border-bottom: 1px solid rgb(224, 219, 219) !important;
}

.disable_applied_rule {
  background-color: rgb(204 204 204) !important;
  cursor: not-allowed !important;
  opacity: 0.85;
}

.ruleset_filter {
  height: 62px;
  padding: 1rem 3rem !important;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 2.5rem;
  justify-content: space-between;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.ruleset_footer {
  display: flex;
  justify-content: end;
  height: 62px;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  justify-content: end;
}

.ruleset_footer_helper {
  justify-content: space-between;
}

.rules_error {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-left: 1rem;
}
.filters {
  display: flex;
  gap: 1rem;
  font-size: 0.7rem;
  & > div {
    display: flex;
    align-items: center;
  }
}

.filter_border {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.alter_date_picker_style {
  border: none;
  width: 9rem;
  display: flex;
  align-items: center;
  border-right: 1px solid #dadadc;
  & > div:first-child {
    border-bottom: none;
    & > div {
      button {
        top: -1px;
      }
      & > div {
        background-color: transparent !important;
        padding-top: 0 !important;
      }
    }
  }
}

.disable_applied_client {
  background-color: rgb(204 204 204) !important;
  cursor: not-allowed !important;
  opacity: 0.85;
  border-bottom: 1px solid rgb(224, 219, 219) !important;
}

.btn_calcel_styling {
  width: max-content;
  height: 40px;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  color: black;
  background-color: #f5f3f7;
  border: none;
  &:hover {
    color: black;
    background-color: #f5f3f7;
  }
}
