.container {
  position: relative;
  color: #352281;
}

.header {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  color: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 8px 6px 9px 6px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.6);
  border: none;
  cursor: pointer;
  white-space: nowrap;
  min-width: 80px;
}

.disabled {
  color: rgba(184, 179, 188, 1);
}

.headerwithVal {
  background-color: rgba(161, 153, 194, 0.15);
}

.content {
  position: absolute;
  z-index: 5;
  font-size: 12px;
  top: 110%;
  left: 0;
  background-color: #ffffff;
  margin-top: 4px;
  border-radius: 4px;
  box-shadow: 0 3px 10px rgba(87, 84, 99, 0.2);
}

.arrowContainer {
  position: absolute;
  top: -8px;
  left: 10px;
  width: 16px;
  height: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  .arrow {
    position: relative;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
  }
}

.options {
  list-style: none;
  padding: 16px 8px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 150px;
  overflow-y: auto;
}

.option {
  width: 140px;
  height: 100%;
  border-radius: 4px;
  padding: 10px 8px 10px 12px;
  cursor: pointer;
}

.option:hover {
  background-color: #eae9f2;
}

.tooltip {
  bottom: -50px;
}
.search_text_style {
  outline: none;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #dadadc;
}
.remove_dropdown {
  display: none;
}
