@import "../../styles/variables.scss";

.rulesTable {
  width: 100%;
  background-color: $primary-blue;
  border-radius: $br5;
  font-size: 14px;
  margin-top: 0.5rem;
  thead {
    background-color: $light-green;
    color: white;
    tr {
      th {
        width: calc((100% - 50%) / 3);
        height: 26px;
        padding: 0.5rem;
        &:nth-child(1) {
          width: 30%;
          padding-left: 5px;
          text-align: left;
        }
        &:nth-child(2) {
          width: 20%;
        }
      }
    }
  }
  tbody {
    tr {
      &.hide {
        display: none;
      }
      td {
        padding: 0.5rem;
        text-align: center;
        font-weight: normal;
        &:nth-child(1) {
          padding-left: 5px;
          text-align: left;
        }

        &:nth-last-child(1) {
          display: flex;
          align-items: center;
          justify-content: center;
          .checkbox {
            height: auto;
            margin: 5px auto;
          }
        }
      }
      background-color: $white;
    }
  }
}

.calcSearch {
  .search::placeholder {
    text-align: center;
  }
}
