.suggestion {
  position: absolute;
  left: 0;
  top: 40;
  &:focus-visible {
    outline: none;
    border: none;
  }
}
.suggestionContainer {
  width: 305px;
  max-height: 160px;
  box-shadow: 0 3px 10px rgba(87, 84, 99, 0.2);
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
  position: relative;
}
.dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 40px;
  border-bottom: 1px solid rgb(87, 84, 99);
  font-size: 14px;
  padding: 10px;
  color: #575463;
  cursor: pointer;
  &:hover {
    background-color: #edeaf7;
    color: black;
  }
  > div:first-child {
    width: 80%;
  }
  > div:last-child {
    flex: auto;
    text-align: end;
  }
}
.triangle {
  width: 0;
  height: 0;
  left: 10px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid white;
  margin: 0 8px;
}
