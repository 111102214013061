@import "../../../styles/variables.scss";

.select {
  width: 100%;
  text-align: left;

  .select {
    &__control {
      border: 1px solid $black;
      box-shadow: none;

      &:hover,
      &:focus-within {
        border-color: $primary-blue;
      }
    }

    &__menu {
      height: auto;
    }

    &__option {
      &:hover {
        background-color: $primary-blue-hover;
        color: $white;
      }

      &--is-selected {
        background-color: $primary-blue !important;
        color: $white;
      }
    }
  }
  .error_border {
    &__control {
      border: 1px solid $red;
      box-shadow: none;
      &:hover,
      &:focus-within {
        border-color: $red;
      }
    }
  }
}
