@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Roboto";
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.sectionContainer {
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 3rem;
}

.sectionContainer .title {
  font-size: 1.2rem;
}
