.mboInputPhase1 {
  width: fit-content;
  height: 41px;
  width: 305px;
  border-radius: 50px;
  background-color: #483981;
  padding: 12px 20px;
  border: none;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: auto;

  &:hover {
    background-color: #6855b4;
  }

  input {
    cursor: inherit;
    background-color: inherit;
    color: inherit;
    border: none;
    width: 90%;
    outline: none;

    &::placeholder {
      color: white;
    }
  }

  .search {
    cursor: pointer;
    width: 12px;
    height: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../componentsPhase1/Icon/Icons/searchIcons.svg");
  }

  .clear {
    cursor: pointer;
    width: 16px;
    height: 16px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../componentsPhase1/Icon/Icons/search-close.svg");
  }
  .loader {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../componentsPhase1/Icon/Icons/textboxloader.svg");
  }
  .show_non_editing_cursor {
    cursor: not-allowed;
    user-select: none;
    pointer-events: none;
  }
}
