.filterSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.input_section {
  .input {
    padding: 8px 6px 9px 6px;
    border-radius: 4px;
    width: 100px;
    height: 33px;
    background-color: #fff;
    color: #352281;
    &::before {
      content: "$";
      margin-right: 4px;
    }
    input {
      &::placeholder {
        color: #352281;
      }
    }

    &::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }
  .calculate_button {
    height: 40px;
    width: 118px;
    font-size: 16px;
    padding: 8px 12px 8px 16px;
    background-color: #211551;
  }

  .disabled_button {
    background-color: rgba(72, 57, 129, 0.25);
  }
}

.filters {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.client_input {
  width: 128px;
  height: 33px;
  background-color: #150b3b;
  text-overflow: ellipsis;
  &:hover {
    background-color: #483981;
  }
}

.manageSection {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.resetButton {
  background-color: inherit;
  color: #352281;
  flex-direction: row-reverse;
  font-weight: 500;
  gap: 6px;
  padding: 0;
  margin-right: 4px;
}
