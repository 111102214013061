.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.rules {
  flex: 1;
  overflow-x: auto;
}

.simulate {
  width: 472px;
  min-height: 100vh;
  background-color: rgba(234, 233, 242, 1);
  overflow-y: auto;
}
.alter_text_style {
  font-size: 1.2rem;
  & > b {
    font-size: 1.4rem;
  }
}
