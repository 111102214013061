@import "../../../styles/variables.scss";

.textAreaInput {
  width: 100%;
  position: relative;
  height: auto;

  .textAreaInput {
    &.textArea {
      padding: 0;
      padding-left: 32px;
      height: 25px;
      width: 100%;
      height: 38px;
      border-radius: $br5;
      border: 1px solid;
      outline: none;

      &.label {
        padding-left: 16px;
      }
      &.error_border {
        border: 1px solid $red;
      }
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-40%);
    pointer-events: none;
  }

  .label {
    display: block;
    margin-bottom: 8px;
    text-align: left;
    width: 100%;
    font-size: 14px;
    padding: 10px;
    max-width: 100%;
    max-height: 150px;
  }
}
