.background {
  z-index: 9999 !important;
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.dev {
  background: url(../watermarkimages/development.png) !important;
  background-repeat: repeat !important;
}

.qa {
  background: url(../watermarkimages/qa.png) !important;
  background-repeat: repeat !important;
}

.preprod {
  background: url(../watermarkimages/pre-prodiction.png) !important;
  background-repeat: repeat !important;
}

.prod {
  background: url(../watermarkimages/production.png) !important;
  background-repeat: repeat !important;
}
