@import "../../styles/variables.scss";

.btn {
  display: inline-block;
  background-color: $primary-blue;
  color: $white;
  border-radius: $br5;
  border: 1px solid $primary-blue;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  padding: 4px 16px;
  cursor: pointer;

  &:hover {
    background-color: $primary-blue-hover;
    color: $white;
  }

  &:disabled {
    background-color: $gray;
    border-color: $gray;
    color: $dark-gray;
    cursor: not-allowed;
  }
}
