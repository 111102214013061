.calculation {
  color: #100a27;
  display: flex;
  flex-direction: column;
  gap: 9px;
  h2 {
    font-size: 18px;
    font-weight: 400;
    margin-top: 0px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 1px;
  .row:nth-child(odd) {
    background-color: rgba(255, 255, 255, 1);
  }
  .row:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.6);
  }
  .row:first-child {
    border-radius: 4px 4px 0 0;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px;
    min-height: 28px;
  }

  .row_split {
    display: inherit;
    gap: 25px;
    text-align: right;
    width: 50%;
    align-items: center;
    justify-content: flex-end;
  }

  .info_icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    width: 50%;
  }

  .final {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px;
    height: 36px;
    border-radius: 0 0 4px 4px;
    font-size: 16px;
    font-weight: bold;
  }
}

.tooltip_container {
  width: 242px;
  border-radius: 4px;
  padding: 16px 0 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #352281;
  font-size: 14px;
  header {
    font-weight: 500;
  }
  main {
    color: #575463;
    white-space: normal;
  }

  a {
    color: #352281;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    text-decoration: none;
  }
}

.visible_tip {
  bottom: 25px;
  left: -12px;
  display: none;
}

.container_tip {
  cursor: pointer;
}

.container_tip:hover .visible_tip {
  display: block;
}
