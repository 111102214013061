@import "../../styles/variables.scss";

.sectionContainer {
  width: 100%;
  min-height: 150px;
  box-shadow: 0 3px 10px rgb(14 14 14 / 20%);
  border-radius: $br5;
  position: relative;
  padding: 16px;
  border: 1px solid $gray;

  span.title {
    background-color: $white;
    color: $black;
    min-width: 100px;
    box-shadow: 0 0 10px rgb(14 14 14 / 20%);
    border: 1px solid $gray;
    width: auto;
    height: 32px;
    padding: 4px 10px;
    border-radius: $br5;
    cursor: pointer;
    position: absolute;
    top: -18px;
    left: 24px;
    display: flex;
    align-items: center;
    text-align: center;
  }
}
