.parent {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  margin: 1rem 2rem;
  height: 95%;
  width: 95%;
}
.tick_mark {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #0000ff17;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  transform: rotate(14deg);
  font-size: 1.5rem;
}

.success_message {
  display: flex;
  gap: 1rem;
  align-items: baseline;
  & > h2 {
    font-weight: normal;
    font-size: 2rem;
  }
  & > h3 {
    font-weight: normal;
    line-height: 1.5rem;
    width: 65%;
    color: #000000a1;
    font-size: 1rem;
  }
}
.button_container {
  position: relative;
  top: 34%;
  left: 81%;
  display: flex;
  gap: 1rem;
}

.btn_update_styling {
  width: max-content;
  cursor: pointer;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  background-color: #1559ea;
  color: white;
  border: none;
  &:hover:not(:active, :disabled) {
    background: #3270eb;
    box-shadow: 0px 1px 2px 0px rgba(21, 89, 234, 0.08),
      0px 2px 4px 0px rgba(21, 89, 234, 0.12),
      0px 4px 8px 0px rgba(21, 89, 234, 0.16),
      0px 8px 16px 0px rgba(21, 89, 234, 0.2);
    backdrop-filter: blur(100px);
  }
  &:active {
    background: #1046b8;
  }
  &:disabled {
    background: rgba(21, 89, 234, 0.25);
    color: white;
  }
}
