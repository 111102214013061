@import "../../../styles/variables.scss";

.numLabel {
  position: relative;
  & > span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    z-index: 1;
    font-size: 0.8rem;
  }

  .number_input {
    width: 100%;
    height: 38px;
    border-radius: 5px;
    border: 1px solid;
    padding: 6px 6px 6px 24px;
    outline: none;
    -moz-appearance: textfield;

    &::placeholder {
      position: relative;
      left: -8px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
