.search_screen_parent {
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
}

.tooltip {
  bottom: -50px;
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.main_heading {
  font-size: 36px;
  font-weight: normal;
  color: #352281;
  font-family: Museo Slab;
}

.sub_heading {
  font-size: 16px;
  font-family: Roboto;
  color: #575463;
  font-weight: normal;
  opacity: 0.8;
}

.simulation_nav {
  color: #352281;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
.job_diva_heading_font {
  font-size: 2rem;
}
