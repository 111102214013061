.btn_create {
  width: max-content;
  height: 32px;
  margin: 1rem;
}
.ruleset_table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  background-color: rgba(234, 233, 242, 1);
}

.btn_create_parent {
  width: inherit;
  padding: 0 2rem 0 3rem;
  display: flex;
  justify-content: space-between;
}
.align_button_to_end {
  justify-content: end;
}
.edit_popover_styling.edit_popover_styling_helper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  &:hover {
    background-color: rgb(222, 220, 228);
  }
  width: 100%;
  border-radius: 5px;
  z-index: 1;
  padding: 10px;
  cursor: pointer;
}
.blocker {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1; /* Place the blocker above other elements */
  pointer-events: auto; /* Enable pointer events on the blocker */
  background-color: #ffffffb0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2; /* Place the overlay above the blocker */
  pointer-events: none; /* Disable pointer events on the overlay */
  /* Additional styling for the overlay if needed */
}
.rules_set_create_popup {
  gap: 1rem;
  width: 70em;
  z-index: 9000;
  overflow-y: auto;
  align-items: start;
  user-select: none;
  height: 35em;
  background-color: #f5f3f7;
  position: fixed;
  box-shadow: 0px 16px 24px rgba(161, 153, 194, 0.24),
    0px 12px 20px rgba(161, 153, 194, 0.24),
    0px 8px 16px rgba(161, 153, 194, 0.2), 0px 4px 8px rgba(161, 153, 194, 0.16),
    0px 2px 4px rgba(161, 153, 194, 0.12), 0px 1px 2px rgba(161, 153, 194, 0.08);
  border-radius: 8px;
  margin: auto;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  justify-self: center;
}

.aligning_container {
  width: 95%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 55%;
  position: relative;

  margin: 0 2rem;

  gap: 2rem;
  top: 3rem;
}

.description_width {
  width: 50% !important;
  margin-bottom: 19px;
  height: 12rem;
}

.name_width {
  width: 50% !important;
}

.alter_desctiption_mandatory {
  top: 12.5rem;

  font-size: 12px;
  color: rgb(185, 34, 34);
  margin-left: 0.5rem;
  position: absolute;
}
.icon_close_positon {
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.btn_position {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-right: 3rem;
  bottom: 0rem;
}
.btn_create {
  height: 2rem;
  font-size: 16px;
}
.btn_calcel_styling {
  height: 3rem;
  width: 7.5rem;
  cursor: pointer;
  color: black;
  font-size: 16px;
  font-weight: 500;
  background-color: #f5f3f7;
  border: none;
  &:hover {
    color: black;
    background-color: #f5f3f7;
  }
}
.form_error_style {
  border-bottom: 2px solid rgb(185, 34, 34);
}
.header {
  padding-top: 2rem;
  color: #352281;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  top: 2rem;
}

.icon_close_positon {
  margin-left: 2rem;
}
.checkBox_styling {
  width: 25rem;
  padding-right: 26px;
  cursor: pointer;
  & > svg {
    width: 22px;
    height: 22px;
  }
}

.ruleset_client_udpate {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  font-weight: 500;
}
.disable_options {
  opacity: 0.5;
  user-select: none !important;
  cursor: not-allowed !important;
  &:hover {
    opacity: 0.5 !important;
    background-color: transparent !important;
  }
}

.disable_btn {
  opacity: 0.5;
  user-select: none !important;
  cursor: not-allowed !important;
}
