@import "../../styles//variables.scss";

.home {
  padding-top: 64px;
  max-width: $pagemaxwidth/1.3;
  margin: auto;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem 2rem 1rem 2rem;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 2px 13px rgb(0 0 0 / 25%);
  & p {
    color: $black;
    text-align: center;
    padding-top: 1rem;
    font-size: 1.3rem;
  }

  [data-title="quotesrates"] {
    opacity: 0.7;
    font-size: 1.1rem;
  }
  &__searchContainer {
    padding: 1rem 2rem 2rem 2rem;
    width: 70%;

    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & input {
      border: 1px solid $gray !important;
      border-right: none !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      padding: 24px 24px 24px 32px !important;
    }

    & button {
      margin-left: -10px;
      border-left: none;
      border-top-left-radius: 0px;
      background-color: $primary-blue;
      color: white;
      border-bottom-left-radius: 0px;
      padding: 1rem 2rem 1rem 2rem;
      text-align: center;
    }
  }

  &__placeholder {
    border: 1px solid;
    margin-top: 128px;
    padding: 16px;
    text-align: center;
    border-radius: 5px;
    width: 50rem;
  }
}
