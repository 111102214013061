@import "../../styles//variables.scss";
.manageRulesParent {
  .action_btn_styles {
    display: flex;

    justify-content: space-around;
    width: 45%;
    & > svg {
      cursor: pointer;
    }
  }
  overflow-y: auto;
  height: calc(100vh - 63px);
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-button {
    background: #eee;
  }
  &::-webkit-scrollbar-track-piece {
    background: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(165, 165, 165);
  }
  &__main {
    width: clamp(50rem, 90vw, 95rem);
    margin: 0 auto;

    &__deleteParent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      height: 100%;
      & div:first-child {
        font-size: 2rem;
      }
      & div:nth-child(2) {
        font-size: 1.2rem;
      }
      & div:nth-child(3) {
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 2rem;
        & button {
          width: 5rem;
          height: 2.2rem;
          font-size: 0.9rem;
        }
      }
    }
    &__parentRuleconfirmation {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      gap: 2rem;

      & > span:first-child {
        font-size: 1.2rem;
        text-align: center;
        font-weight: normal;
        gap: 6px;
        display: flex;
        flex-direction: column;
        width: 70%;
      }
      & > span:nth-child(2) {
        font-size: 16px;
        text-align: center;
      }
      & > div {
        display: flex;
        gap: 4rem;
        & > button {
          width: max-content;
          padding: 0.4rem 0.5rem 0.4rem 0.5rem;
        }
      }
    }

    & > .manageRuleSectionContainer {
      padding-top: 1rem;
      min-height: max-content;
      max-height: 58vh;
      .btn-addnewoptional {
        left: 91%;
        position: sticky;
        margin-bottom: 3px;
        cursor: pointer;
      }

      .nodatafound_parent {
        height: max-content !important;
        display: grid;
        justify-content: center;
        & > .nodatafound {
          width: 6rem;

          position: relative;
          text-align: center;
          height: max-content;
          left: 46%;
          transform: translate(-50%);
          margin-top: 2rem;
        }
        & > .nodatafoundText {
          position: relative;
          text-align: center;
          height: max-content;
          left: 50%;
          transform: translate(-50%);
          opacity: 0.4;
          margin-bottom: 2rem;
          top: 4px;
        }
      }

      & .tableMaxHeight {
        overflow: hidden;
        min-height: max-content;
        max-height: 48vh !important;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
      }
      table {
        width: 100%;
        background-color: $primary-blue;
        font-size: 14px;
        margin: 0.5rem 0.5rem 0.5rem 0;
        thead {
          background-color: $primary-blue-hover;
          color: white;
          tr {
            text-align: left;
            th {
              padding: 0.5rem;
              width: calc((100% - 50%) / 3);
              height: 26px;
              &:nth-child(1) {
                background-color: $primary-blue-hover;
                border-radius: $br5;

                width: 150px;
                padding-left: 5px;
                text-align: left;
                min-width: 150px;
                max-width: 150px;

                border-right: 1px solid $primary-blue;
              }
              &:nth-child(2) {
                background-color: $primary-blue-hover;
                border-radius: $br5;

                width: 150px;
                min-width: 150px;
                max-width: 150px;

                border-right: 1px solid $primary-blue;
              }
              &:nth-child(3) {
                background-color: $primary-blue-hover;
                border-radius: $br5;

                width: 200px;
                min-width: 200px;
                max-width: 200px;

                outline: 2px solid $primary-blue;
              }
              &:nth-child(n + 3) {
                width: 150px;
                min-width: 150px;
                max-width: 150px;
              }
            }
          }
        }
        tbody {
          tr {
            &.hide {
              display: none;
            }
            td {
              padding: 0.5rem;
              text-align: left;
              font-weight: normal;
              &:nth-child(1) {
                width: 150px;
                padding-left: 5px;
                text-align: left;
                min-width: 150px;
                max-width: 150px;

                background: white;
                outline: 2px solid $primary-blue;
              }
              &:nth-child(2) {
                width: 150px;
                min-width: 150px;
                max-width: 150px;

                background: white;
                outline: 2px solid $primary-blue;
              }
              &:nth-child(3) {
                width: 200px;
                min-width: 200px;
                max-width: 200px;

                background: white;
                outline: 2px solid $primary-blue;
              }
              &:nth-child(n + 3) {
                width: 150px;
                min-width: 150px;
                max-width: 150px;
              }
            }
            background-color: $white;
          }
        }
      }
    }
    &__businessModal {
      height: 100%;
      width: 100%;
      overflow: hidden;
      & > header {
        display: grid;

        grid-template-columns: repeat(7, 1fr);
        gap: 1rem;
        // background-color: $primary-blue;
        border-bottom: 1px solid rgb(33, 21, 81);
        padding: 1rem;
        place-items: center;
        & > div {
          display: flex;
          gap: 1rem;
          grid-column-start: 8;
          align-items: center;
        }
        & > h3 {
          color: $white;
          color: $primary-blue;
          grid-column-start: 4;
          width: max-content;
          text-align: center;
        }
        & button {
          outline: none;
          border: none;
          cursor: pointer;
          font-size: 1.2rem;
          background: none;
          color: white;
          color: $primary-blue;
          font-weight: bold;
        }
        & svg {
          fill: white;
          fill: $primary-blue;
          cursor: pointer;
        }
      }

      &__bodyParent {
        padding: 1rem 3rem 0 3rem;
        height: 83%;
        overflow-y: auto;
        &__section {
          & > span {
            margin-bottom: 5px;
          }
          padding-bottom: 1rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 3rem;
          align-items: baseline;
          &__Name,
          &__Description,
          &__Amount {
            & > span {
              top: 9px;
            }
          }
          &__Amount {
            height: 84px;
            top: 1px;
          }
          &__Attribution,
          &__CreatedDate,
          &__UpdateDate {
            height: 84px;
          }

          &__Name,
          &__Effectivedate,
          &__group,
          &__Attribution,
          &__Description,
          &__Expiredate,
          &__operation,
          &__Amount,
          &__CreatedDate,
          &__UpdateDate,
          &__Country,
          &__MainDivision,
          &__City,
          &__EngagementType,
          &__RateLow,
          &__Country,
          &__RateHigh,
          &__PerformedBy {
            & > span {
              font-size: 14px;
              margin-bottom: 8px;
            }
            display: grid;
            grid-template-rows: 1fr 1fr;
            align-items: end;
            height: max-content;
            gap: 0px;
            grid-template-rows: 1fr;
          }
        }
      }
      .btn-editButtonStyle {
        position: relative;
        left: 45%;
        top: 1rem;
      }
      .btn-cancelButtonStyle {
        position: relative;
        left: 40%;
        top: 1rem;
      }
    }
    &__clientListParent {
      position: relative;
      z-index: 5;
      display: grid;
      justify-items: center;
      align-items: center;
      margin: 2rem 1rem 2rem 0px;
      gap: 5px;
      grid-template-columns: 1fr;

      & > span {
        display: none;
      }

      & .select .select__control {
        border: none;
        height: 3rem;
        padding-left: 5px;
        &::before {
          content: "";
          background: url("../Icon/Icons/searchIcons.svg");
          width: 1rem;
          height: 1rem;
          margin-left: 6px;
          background-repeat: no-repeat;
        }

        & .select__indicator-separator {
          display: none;
        }
        & .select__clear-indicator {
          & svg {
            display: none;
          }
          &::after {
            content: "clear";
            cursor: pointer;
          }
        }
      }

      &__clientList {
        &:hover {
          & ~ span {
            display: block;
            width: max-content;
            padding: 0.5rem 1rem 0.5rem 1rem;
            height: max-content;
            position: absolute;
            top: 4rem;
            background: white;
            box-shadow: 0 3px 10px rgb(14 14 14 / 20%);
            border-radius: 5px;
            z-index: 100;
            font-size: 12px;
            &::before {
              content: "";
              background: url("../Icon/Icons/triangle.svg");
              width: 1rem;
              height: 1rem;
              background-repeat: no-repeat;
              position: absolute;
              left: 41%;
              bottom: 78%;
            }
          }
        }
        width: 32%;
        transition: width 0.5s;
        &:focus-within {
          width: 50%;
          transition: width 0.5s;
        }
        box-shadow: 0 3px 10px rgb(14 14 14 / 20%);
        border-radius: 10px;
        position: relative;
        padding: 4px;
        border: 1px solid #ccc;
        & .select__dropdown-indicator {
          display: none;
        }
      }

      &__clientListlabel {
        height: 100%;
      }
    }
    &__clientListopacity {
      z-index: 1;
    }
  }
}
