// colors

$black: #000;
$white: #fff;
$gray: #ccc;
$dark-gray: #918f8f;
$light-gray: #eee;
$rose: #fb7185;
$light-rose: #fda4af;
$cyan-blue: #bdf;
$purpleLight: #c5cae9;
$red: #ff0000;
$primary-blue: rgb(33, 21, 81);
$primary-blue-hover: rgb(62 48 116);
$light-green: #007f9c;

// font size

$f14: 14px;

// border radius
$br5: 5px;

// page max width

$pagemaxwidth: 90%;
