.checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  position: relative;

  &__input {
    width: 16px;
    height: 16px;
    position: absolute;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }

  &__label {
    line-height: 1;
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.left {
  .checkbox {
    &__label {
      margin-right: 10px;
      order: 0;
    }
    &__input {
      right: 0;
    }
  }
}
.right {
  .checkbox {
    &__label {
      margin-left: 10px;
      order: 1;
    }
  }
}
