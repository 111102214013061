@import "../../styles/variables.scss";

.browser_print {
  position: absolute;
  width: max-content;
  background: white;
  color: #211451;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 3px 10px rgb(33 30 81);
  display: none;
  align-items: center;
  flex-direction: row;
  gap: 0.7rem;
  z-index: 9999;
  cursor: pointer;
  font-weight: bold;
}
.pricingbody {
  overflow-y: auto;
  height: calc(100vh - 63px);
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-button {
    background: #eee;
  }
  &::-webkit-scrollbar-track-piece {
    background: #eee;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(165, 165, 165);
  }
  &__details {
    width: clamp(50rem, 90vw, 95rem);
    margin: 0 auto;
    padding-top: 2rem;
    &__jobDiva {
      margin-top: 2rem !important;
    }
    .section {
      margin: 16px 0;
    }
    &__pricingCalc {
      &__sow_info {
        display: flex;
        gap: 2rem;

        & > div {
          font-weight: normal;
          display: flex;
          gap: 1rem;
          margin-top: 0.5rem;
          margin-left: 1rem;
          & > span {
            font-weight: bold;
          }
        }
        min-height: max-content !important;
        & > span {
          min-width: max-content !important;
        }
      }
    }
    &__search {
      width: 60%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: width 0.5s;
      padding: 1rem;
      border-radius: 10px;
      box-shadow: 0 2px 13px rgb(0 0 0 / 25%);

      & input {
        border: 1px solid $gray !important;
        border-right: none !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        padding: 24px 24px 24px 32px !important;
      }

      & button {
        margin-left: -10px;
        border-left: none;
        border-top-left-radius: 0px;
        background-color: $primary-blue;
        color: white;
        border-bottom-left-radius: 0px;
        padding: 1rem 2rem 1rem 2rem;
        text-align: center;
      }
    }
    &__simulateSection {
      margin: 24px auto;
      text-align: center;

      .buttons,
      .inputs {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      .inputs {
        flex-direction: column;
        margin-bottom: 16px;
      }

      h2,
      h3 {
        margin-bottom: 24px;
      }
    }
    &__calcContainer {
      margin-top: 50px;
      padding: 15px;
      &__header {
        &__section {
          display: grid;
          grid-template-columns: 1.5fr 1fr;

          &__1 {
            display: grid;
            &:nth-child(1) {
              grid-template-columns: 0.25fr 1fr;
            }
            &:nth-child(2) {
              grid-template-columns: 1fr 1fr;
            }

            align-items: center;
            gap: 16px;
            flex: 1;
            margin-bottom: 8px;
            &__error {
              color: $red;
              top: 5px;
              font-size: 0.8rem;
            }
            &__label {
              flex: 1;
              text-align: right;
            }
            span[data-name="Recruiting Services"],
            span[data-name="Effective Date"] {
              flex: 0.4;
              text-align: left;
            }
            .input {
              .label {
                margin: 0;
              }
            }

            .dateContainer,
            .commentsConatiner {
              width: 300px;
              font-weight: normal;
            }

            .radioContainer {
              .radio {
                display: flex;
              }
            }

            &:nth-last-child(1) {
            }

            &:nth-child(1) {
              justify-content: flex-start;
            }
          }
        }
      }

      &__body {
        margin-top: 50px;
        margin-bottom: 32px;

        &__contents {
          margin-bottom: 32px;
        }
      }

      &__footer {
        display: flex;
        justify-content: center;
        gap: 64px;
        align-items: center;

        .btn-calcButtons {
          padding-left: 32px;
          padding-right: 32px;
        }
      }
    }
    .business_rules_sectioncontainer {
      flex-direction: column;
      display: flex;
    }
  }

  &__error {
    position: relative;
    border-radius: 10px;
    margin: 1rem;

    top: 0rem;
    height: max-content;
    background: rgb(255 207 209 / 50%);
    display: grid;
    place-content: center;
    align-items: center;
    padding: 1rem;
    grid-template-columns: 0.01fr 1fr;
    height: max-content;

    button {
      width: 24px;
      height: 24px;
      background: #f13737;
      outline: none;
      border-radius: 50%;
      transform: rotate(45deg);
      margin-right: 20px;
      border: none;
      color: #000;
      font-size: 1.1rem;
      padding-left: 2px;
      padding-bottom: 3px;
    }
    p {
      color: $black;
      font-size: 1rem;
      font-weight: normal;
      text-align: left;
      margin-left: 9px;
      text-align: justify;
    }
  }
}
