.input_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
  .input {
    border-radius: 2px;
    width: 260px;
    height: 44px;
    background-color: #fff;
    border-bottom: 2px solid #a199c2;
    color: #352281;
    &::before {
      content: "$";
    }
    input {
      &::placeholder {
        color: #352281;
      }
    }
    &::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }
  .calculate_button {
    height: 40px;
    width: 118px;
    font-size: 16px;
    padding: 8px 12px 8px 16px;
    background-color: #211551;
  }

  .disabled_button {
    background-color: rgba(72, 57, 129, 0.25);
  }
}
.warning_message {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  line-height: 20px;
  color: #575463;
  margin-top: -10px;
}

.calculation {
  color: #100a27;
  display: flex;
  flex-direction: column;
  gap: 9px;
  h2 {
    font-size: 18px;
    font-weight: 400;
  }
}

.content {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 1px;
  .row:nth-child(odd) {
    background-color: rgba(255, 255, 255, 1);
  }
  .row:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.6);
  }
  .row:first-child {
    border-radius: 4px 4px 0 0;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px;
    height: 28px;
  }

  .info_icon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  .row_split {
    display: flex;
    gap: 20px;
  }
  .final {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 12px;
    height: 36px;
    border-radius: 0 0 4px 4px;
    font-size: 16px;
    font-weight: bold;
  }
}

.tooltip_container {
  width: 242px;
  border-radius: 4px;
  padding: 16px 0 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #352281;
  font-size: 14px;
  header {
    font-weight: 500;
  }
  main {
    color: #575463;
    white-space: normal;
  }
  footer {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
  }
}

.visible_tip {
  bottom: 30px;
  left: -12px;
  display: none;
}

.container_tip {
  cursor: pointer;
}

.container_tip:hover .visible_tip {
  display: block;
}

.simulate_disable {
  opacity: 0.4;
  pointer-events: none;
}
.error_icon {
  width: 4%;
  margin-top: 2px;
}
.error_message {
  width: 96%;
}
