@import "../../../styles/variables.scss";

.datePicker {
  width: 100%;
  height: 38px;
  border-radius: $br5;
  outline: none;
  border: 1px solid;

  .react-datepicker-wrapper {
    height: 100%;
    padding-left: 16px;

    & input {
      outline: none;
    }

    & * {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: $br5;
    }
    .react-datepicker__close-icon {
      width: max-content;
      height: max-content;
      &::after {
        cursor: pointer;
        background-color: transparent;
        color: hsl(0deg 5% 71%);
        border-radius: 50%;

        padding: 2px;
        font-size: 12px;
        line-height: 3;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        content: "X";
      }
    }
  }
}
.react-datepicker__day {
  &--selected,
  &--keyboard-selected {
    background-color: $primary-blue;
    color: $white;
  }
}
