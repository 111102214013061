@import "../../styles/variables.scss";

.datePicker1 {
  width: 98%;
  height: 38px;
  border-radius: 0;
  outline: none;
  border: 1px solid;
  position: relative;
  display: flex;
  .react-datepicker-wrapper {
    height: 100%;
    padding-left: 0px !important;
    border-bottom: 2px solid;
    & input {
      outline: none;
      padding-left: 10px !important;
    }
    .datePicker1_selected {
      background: #e4dff0;
      padding-top: 10px;
    }

    .datepicket1_custom_input {
      display: flex;
      flex-direction: row-reverse;
      justify-content: start;
      gap: 0.5rem;
      height: 100%;
      width: 100%;
      align-items: center;
      padding-left: 0.6rem;
      position: relative;

      .datepicket1_custom_input_label_top {
        position: absolute;
        bottom: 65%;
        font-size: 10px;
      }
      .datepicket1_custom_input_label {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        font-size: 0.8rem;
        opacity: 0.7;
        color: #000000;
      }
    }

    & > * {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: $br5;
    }
    .react-datepicker__close-icon {
      width: max-content;
      height: max-content;
      &::after {
        cursor: pointer;
        background-color: transparent;
        color: hsl(0, 0%, 0%);
        border-radius: 50%;

        padding: 2px;
        font-size: 12px;
        line-height: 3;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        content: "X";
      }
    }
  }

  .react-datepicker-popper {
    z-index: 2;
  }
  .selected_fill {
    background-color: rgb(228 223 240) !important;
    border: 1px solid rgba(104, 85, 180, 0.08) !important;

    border-radius: 0px;
  }
}
.react-datepicker__day {
  &--selected,
  &--keyboard-selected {
    background-color: $primary-blue;
    color: $white;
  }
}
.datepicket1_errortext {
  top: 50px;
  font-size: 12px;
  color: rgb(185, 34, 34);
  margin-left: 0.5rem;
  position: absolute;
}
