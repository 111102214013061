@import "../../styles//variables.scss";

.autocomplete_dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  border: none;
  outline: none;
  width: 50%;
  height: 2.5rem;
  border-bottom: 1px solid #a199c2;
  & > div {
    width: 100%;
    display: flex;
  }
}
.options {
  position: absolute;
  top: 3.1rem;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 2;
}

.form_list_li {
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }

  &:last-child {
    border-bottom: none;
  }
}
.formInput {
  font-size: 14px;
  border: none;
  outline: none;
  width: 95%;
  height: 100%;
  padding-left: 0.5rem;
  color: #575463;
  position: relative;
  padding-right: 2rem;
  text-overflow: ellipsis;
}
.mandatory {
  top: 44px;
  font-size: 11px;
  color: rgb(185, 34, 34);
  margin-left: 0.5rem;
  position: absolute;
}

.selected_fill {
  border-bottom: 2px solid;
  border-radius: 0px;
  width: 50%;
  font-size: 0.9rem;
}
.selected_fill_selected {
  background-color: rgba(104, 85, 180, 0.08);
  border: 1px solid rgba(104, 85, 180, 0.08);
  border-bottom: 2px solid;
  border-radius: 0px;
  padding-top: 10px;
  width: 50%;
  font-size: 0.9rem;
}
.selected_fill_selected_input {
  background-color: rgb(228 223 240) !important;
}

.label_with_data {
  position: absolute;
  z-index: 1;
  font-size: 10px;
  width: 100%;
  color: #352281;
  top: 0px;
  opacity: 0.7;
  padding-left: 0.5rem;
  padding-top: 3px;
}

.dropdown_arrow {
  font-size: 1.6rem;
  color: gray;
  background-color: white;
  height: 100%;
  padding-right: 10px;
}

.disable_component {
  cursor: not-allowed;
  pointer-events: none;
}
.option {
  outline: none;

  & :focus-visible {
    outline: none;
    border: none;
  }
}

.error_messsage_fullwidth {
  width: 101%;
  color: $primary-blue;
  margin-left: 0;
}
