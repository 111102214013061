@import "../../styles/variables.scss";

.jobdiva {
  width: 100%;
  text-align: left;
  font-size: $f14;
  padding-top: 0.5rem;

  &__header {
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto auto auto auto auto;
    padding: 5px 0;
    grid-gap: 1.5rem;
    row-gap: 20px;
  }
  hr {
    margin: 5px -16px 0;
    border-top: 1px dashed $black;
  }
  &__tables {
    display: flex;

    hr {
      height: inherit;
      border: 0.5px solid $black;
    }

    &__content {
      flex: 1;
      height: 16rem;
      overflow-y: auto;
      table {
        width: 100%;
        position: relative;
        tr {
          display: flex;
          justify-content: space-around;
          min-width: 100px;

          th,
          td {
            text-align: center;
            margin: 5px 0;
            width: 100%;
          }
        }
      }
    }
  }
}
