@import "../../styles/variables";

.header {
  height: 59px;
  background-color: $primary-blue;
  color: $white;
  box-shadow: auto;
  &__container {
    max-width: $pagemaxwidth;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: clamp(50rem, 90vw, 95rem);
    &__items {
      display: flex;
      gap: 16px;
      width: 100%;
      height: 100%;
      justify-content: space-between;
      align-items: center;
      &__icon {
        align-self: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 0.7rem;
        & > span {
          left: -13px;
          top: -1px;
        }
      }
      &__navParent {
        display: flex;
        gap: 21px;
        &__navlink {
          color: $white;
          text-decoration: none;
          font-weight: 100;
        }
        &__active {
          text-decoration: none;

          &::after {
            content: "";
            width: 100%;
            position: absolute;
            left: 0;
            bottom: -20px;
            height: 1px;
            border-width: 0 0 3px;
            border-radius: 10px;
            border-style: ridge;
            background-color: #6855b4 !important;
            color: #6855b4 !important;
          }
        }
      }

      .links {
        display: flex;
        align-items: center;
        gap: 32px;
        justify-content: flex-start;
        list-style: none;
        .link {
          .name {
            font-weight: bold;
            letter-spacing: 0.5px;
          }

          &:last-child {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.disable_header {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}
