.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  gap: 4px;
  padding: 0px 8px 0px 12px;
  border: none;
  border-radius: 4px;
  background-color: #ccc;
  font-size: 12px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  line-height: 16px;
  cursor: pointer;
  background-color: rgba(21, 89, 234);
}

.disabled {
  background-color: rgba(21, 89, 234, 0.25);
  cursor: auto;
}

.tooltip {
  bottom: -50px;
}
