.candidate_parent {
  display: grid;
  align-items: center;

  gap: 0.2rem;
  grid-template-columns: max-content 10% 10% 11% 9% 8% 9% 11% 12%;
  width: 100%;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;

  margin-right: -18px;
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 50%;
  background-color: #211451;
  margin-right: -18px;
  color: white;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
}

.candidate_name,
.client_name,
.start_date,
.engagement_type,
.location,
.sowName,
.sowMarkup {
  display: flex;
  flex-direction: column;
  align-items: start;
  font-size: 14px;
  gap: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  clear: both;

  display: block;

  & > p:nth-child(2) {
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #100a27;
  }
  & > p:nth-child(1) {
    font-size: 0.7rem;
    opacity: 0.7;
    font-weight: normal;
    padding-bottom: 5px;
  }
  border-left: 1px solid #a199c2;
  padding-left: 1rem;
}

.candidate_name {
  border: none;
  grid-column: 1/3;
}

.divTableCell {
  display: flex;
  gap: 5px;
  & > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    clear: both;
    overflow: hidden;
  }
}

.value_styling {
  font-size: 0.7rem !important;

  font-weight: normal !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;

  white-space: nowrap !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #100a27 !important;
}

.reduce_width_for_supplier {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.name_styling {
  font-weight: normal !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;

  white-space: nowrap !important;

  color: #100a27 !important;
}

.supplier_data_style {
  width: 4rem;
}

.visible_tip {
  bottom: 35px;
  left: 10px;
  display: none;
  width: max-content;
}

.container_tip:hover .visible_tip {
  display: block;
}

.tooltip_container {
  & > :first-child {
    font-weight: normal;
  }

  & > :last-child {
    font-weight: 600;
  }
}

.container_tip {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.parent_container {
  display: flex;
  width: 92.5%;
  gap: 10px;
  margin: 1rem 2rem;

  .candidate_parent_without_sow_supplier {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.quad_parent_container,
.parent_container_2 {
  display: flex;
  gap: 10px;

  .quad_candidate_parent,
  .candidate_parent_without_sow_supplier {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    margin-left: 1rem;

    & > * {
      padding-right: 0.5rem;
      max-width: 120px;
    }

    & > *:not(:first-child) {
      border-left: 1.5px solid rgba(161, 153, 194, 0.15);
      padding-left: 0.5rem;
    }

    & > * > * {
      border-left: none !important;
      padding-left: 0 !important;
    }
  }
}

.quad_parent_container {
  margin: 0rem 4rem;
  margin-bottom: 1.5rem;
}

.simulation_avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: green;
  margin-right: -18px;
}

.simulation_client_details_parent {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  gap: 15px;
}

.simulation_candidate_info {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.4rem;
}

.simulation_candidate_name {
  font-size: 1.9rem;
  color: #352281;
  font-weight: 500;
}
.simulation_candidate_title {
  font-size: 1rem;
  opacity: 0.6;
  margin-left: 3px;
}
.simlation_avatar_parent {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.simulation_candidate_parent {
  width: 50%;
  display: flex;
  margin-bottom: 3rem;
  gap: 1rem;
}
