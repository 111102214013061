/* toast */
.toast {
  color: #000;
  line-height: 1.5;
  margin-bottom: 1em;
  position: fixed;
  z-index: 9999;
  padding: 5px 10px;
  right: -365px;
  top: 1em;
  transition: 0.15s ease-in-out;
  min-width: 316px;
  max-width: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__close {
    cursor: pointer;
    float: right;
    font-size: 1.25rem;
    line-height: 1;
    margin-left: 1em;
    opacity: 0.8;
    &::after {
      content: "\00d7";
      font-size: 2rem;
    }
  }
}

.show_toast {
  transform: translateX(-370px);
}
.toast_bg_error {
  color: #d8000c;
  background-color: #ffd2d2;
  border: 1px solid #d8000c;
  border-left: 7px solid #d8000c;
  border-radius: 4px;
}
.toast_bg_success {
  color: #4f8a10;
  background-color: #dff2bf;
  border: 1px solid #4f8a10;
  border-left: 7px solid #4f8a10;
  border-radius: 4px;
}

.toast_bg_warning {
  color: #9f6000;
  background-color: #feefb3;
  border: 1px solid #9f6000;
  border-left: 7px solid #9f6000;
  border-radius: 4px;
}
