.input {
  width: 20rem;
  height: 2.75rem;
  border: none;
  border-bottom: 2px solid;
  outline: none;
  color: #575463;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  font-size: 0.9rem;
  resize: none;
}

.input_selected {
  width: 20rem;
  height: 2.75rem;
  border: none;
  border-bottom: 2px solid;
  outline: none;
  color: #575463;
  padding-left: 0.4rem;
  padding-top: 20px;
  font-size: 0.9rem;
  resize: none;
}

.mandatory {
  top: 48px;
  font-size: 12px;
  color: rgb(185, 34, 34);
  margin-left: 0.5rem;
  position: absolute;
}
.parent {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.selected_fill {
  background-color: rgb(228 223 240) !important;
  border: 1px solid rgba(104, 85, 180, 0.08) !important;
  border-bottom: 2px solid !important;
  border-radius: 0px;
}
.label_with_data {
  position: absolute;
  z-index: 1;
  font-size: 12px;
  color: #352281;
  top: 3px;

  opacity: 0.7;
  padding-left: 0.5rem;
}
.component_childs {
  width: inherit;
  position: relative;
}
.text_count {
  position: absolute;
  left: 87%;
  display: flex;
  flex-direction: row;
  bottom: 9%;
  font-size: 0.7rem;
  background: #e9e9e9f7;
  padding: 1px;
  box-shadow: 0px 0px 8px 1px #e9e9e9f7;
}
