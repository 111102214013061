.rate_parent {
  display: flex;
  margin: 1rem 2rem;
  gap: 1rem;
  height: 8.5rem;
  overflow: hidden;
}
.rate_parent_remove_gap {
  gap: 2px;
}
.rate_parent_full_data {
  height: 100%;
}
.rate_parent_with_showall {
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.divTable {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.simulation_divTable {
  display: flex;
  width: 65%;
  &:first-child {
    width: 35%;
  }
  flex-direction: column;
}

.headers {
  display: flex;
  flex-direction: row;
  width: 100%;

  & > div {
    padding: 0.5rem 0.5rem;
    &:first-child {
      font-weight: 500;
    }
    width: 33.3%;
  }
}

.headers_jobDiva {
  font-size: 0.8rem;
  & > div {
    &:first-child {
      width: 20%;
    }
    width: 40%;
  }
}

.body {
  display: flex;
  flex-direction: column;
}

.rowData {
  display: flex;
  flex-direction: row;
  width: 100%;

  &:nth-child(even) {
    background: #f8f8f8 !important;
  }
  &:nth-child(odd) {
    background: white !important;
  }
  & > div {
    align-items: start;
    border-bottom: 1px solid #e4e4e4;
    font-size: 0.9rem;
    padding: 0.5rem;
    width: 33.3%;

    &:first-child {
      font-weight: 500;
    }

    &:nth-child(2),
    &:nth-child(3) {
      & > p {
        width: 1.5rem;
      }
      & > span {
        opacity: 0.3;
      }
      display: flex;
      gap: 5px;
    }
  }
}

.rowData_jobDiva {
  align-items: center;
  height: 42px;
  border-bottom: 1px solid #e4e4e4;

  & > div {
    font-size: 0.8rem;
    width: 40%;
    border-bottom: none;
    &:nth-child(3) {
      margin-right: 1rem;
      padding-left: 13px;
    }
    &:first-child {
      width: 20%;
    }
  }
}

.show_all {
  font-size: 0.8rem;
  left: 87%;
  display: flex;
  bottom: 9%;
  cursor: pointer;
  gap: 5px;
  width: max-content;
  color: #352281;
}

.job_diva_rates_style {
  margin: 1rem 2rem 0.2rem 2.1rem;
  color: rgb(53, 34, 129);
  display: flex;
  align-items: baseline;
  gap: 10px;
  font-size: 1.1rem;

  & > p:last-child {
    font-weight: 500;
  }
}

input[type="radio"].radio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

input[type="radio"].radio + label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 25px;
  height: 25px;
}

input[type="radio"].radio + label:before {
  content: "";
  position: absolute;
  left: 0;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #a199c2;
}

.group:hover input[type="radio"].radio.groupHover + label:before {
  border-radius: 50%;
  border: 1px solid rgba(161, 153, 194, 0.5);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(161, 153, 194, 0.08),
    0px 2px 4px 0px rgba(161, 153, 194, 0.12),
    0px 4px 8px 0px rgba(161, 153, 194, 0.16),
    0px 8px 16px 0px rgba(161, 153, 194, 0.2);
}

input[type="radio"].radio:checked + label:before {
  border-radius: 50%;
  border: 1px solid #150b3b;
  background: #fff;
}

input[type="radio"].radio:checked + label:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #150b3b;
}

.cursorPointer {
  cursor: pointer;
}

.justifyCenter {
  justify-content: center;
}

.itemsStart {
  align-items: start;
}

.itemsCenter {
  align-items: center;
}

.flex {
  display: flex;
}
.radio_marginLeft {
  margin-left: -34px;
}
.divTableCell {
  & > p {
    width: max-content !important;
  }
}
