@import "../../../styles/variables.scss";

.radio {
  border: none;
  width: auto;
  display: flex;
  gap: 18px;
  &__radio_btn_parent {
    display: flex;
    gap: 1.5rem;
  }
  label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;
    margin-left: -1rem;
    &:last-child {
      margin-right: 0;
    }

    span {
      margin-left: $br5;
    }
  }
}
