.popup_blur {
  z-index: 9000;
  pointer-events: none;
  cursor: not-allowed;
  position: fixed;
  user-select: none;
  background-color: #ffffffb0;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rules_edit_popup {
  width: 65.5em;
  z-index: 9000;
  user-select: none;
  height: 39.5em;
  background-color: #f5f3f7;
  position: fixed;
  box-shadow: 0px 16px 24px rgba(161, 153, 194, 0.24),
    0px 12px 20px rgba(161, 153, 194, 0.24),
    0px 8px 16px rgba(161, 153, 194, 0.2), 0px 4px 8px rgba(161, 153, 194, 0.16),
    0px 2px 4px rgba(161, 153, 194, 0.12), 0px 1px 2px rgba(161, 153, 194, 0.08);
  border-radius: 8px;
  margin: auto;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}

.blocker {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1; /* Place the blocker above other elements */
  pointer-events: auto; /* Enable pointer events on the blocker */
  background-color: #ffffffb0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2; /* Place the overlay above the blocker */
  pointer-events: none; /* Disable pointer events on the overlay */
  /* Additional styling for the overlay if needed */
}

.form_container {
  width: 100%;

  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.sow_form_container_gap {
  gap: 4rem;
  padding: 0 2rem;
}
.aligning_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  position: relative;
  overflow: hidden;
}

.header {
  margin: 0;
  color: #352281;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}
.sub_header {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #575463;
}

.elements_position {
  display: flex;
  display: flex;
  grid-gap: 2rem;
  width: 100%;
}

.alter_location_width {
  width: 100%;
}

.location_styles {
  display: flex;
  gap: 1rem;
  width: 50%;
  & > div {
    &:first-child {
      flex: 0.7;
    }
    &:last-child {
      flex: 0.4;
      margin-right: 4px;
    }
  }
}

.btn_update_styling {
  width: 84px;
  cursor: pointer;
  height: 40px;
  background-color: #1559ea;
  color: white;
  &:hover {
    background-color: #1559ea;
  }
}

.btn_calcel_styling {
  width: 84px;
  height: 40px;
  cursor: pointer;
  color: black;
  background-color: #f5f3f7;
  border: none;
  &:hover {
    color: black;
    background-color: #f5f3f7;
  }
}
.btn_position {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 10px;
}
.alter_date_styles {
  background: white;
  border: none;
  border-radius: 1px;
  border-bottom: 1px solid;
  height: 44px;
  width: 99%;
}

.form_error_style {
  border-bottom: 2px solid rgb(185, 34, 34);
}
.blocker {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1; /* Place the blocker above other elements */
  pointer-events: auto; /* Enable pointer events on the blocker */
  background-color: #ffffffb0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2; /* Place the overlay above the blocker */
  pointer-events: none; /* Disable pointer events on the overlay */
  /* Additional styling for the overlay if needed */
}

.full_width {
  width: 100%;
}

.jobdiva_list_fixed_height {
  height: 10rem;
  overflow-y: auto;
}
