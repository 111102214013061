.jobdivarates_parent {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin: 2rem 2rem;
  position: relative;
  margin-bottom: 4rem;
  &::before {
    content: "Current Jobdiva rates";
    position: absolute;
    bottom: 102%;
    font-size: 1rem;
  }

  &::after {
    content: "New Rates";
    position: absolute;
    bottom: 104%;
    left: 58%;
    font-size: 1rem;
    font-weight: 500;
  }
}

.current_rates {
  height: max-content;
  border-radius: 5px;
  width: 35%;
  border: 1px solid #a199c2;
  font-size: 0.85rem;
}
.divider {
  width: 20%;
  height: 1rem;
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: #007f9c;
  }
  &::after {
    content: "\2303";
    transform: rotate(89deg);
    position: absolute;
    font-size: 1.5rem;
    left: 92%;
    bottom: 4%;
    color: #007f9c;
  }
}
.new_rates {
  height: max-content;
  width: 35%;
  border-radius: 5px;
  border: 2px solid #007f9c;
  font-size: 0.9rem;
}

.current_rate_values {
  height: 25%;
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:nth-child(even) {
    background-color: rgba(161, 153, 194, 0.15);
  }
  &:nth-child(odd) {
    background-color: #ffffff;
  }
}

.new_rate_values {
  height: 25%;
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:nth-child(even) {
    background-color: rgba(213, 247, 255, 0.2);
  }
  &:nth-child(odd) {
    background-color: #ffffff;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 93%;
  margin: 1rem 2rem;
}
.checkbox_style {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  gap: 3px;
}

.visible_tip {
  bottom: 30px;
  left: -12px;
  display: none;
}

.container_tip {
  cursor: pointer;
}

.container_tip:hover .visible_tip {
  display: block;
}

.button_alignment {
  display: flex;
}

.btn_update_styling {
  width: max-content;
  cursor: pointer;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  background-color: #1559ea;
  color: white;
  border: none;
  &:hover:not(:active, :disabled) {
    background: #3270eb;
    box-shadow: 0px 1px 2px 0px rgba(21, 89, 234, 0.08),
      0px 2px 4px 0px rgba(21, 89, 234, 0.12),
      0px 4px 8px 0px rgba(21, 89, 234, 0.16),
      0px 8px 16px 0px rgba(21, 89, 234, 0.2);
    backdrop-filter: blur(100px);
  }
  &:active {
    background: #1046b8;
  }
  &:disabled {
    background: rgba(21, 89, 234, 0.25);
    color: white;
  }
}

// sow styling

.sow_jobdivarates_parent {
  width: 79%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  margin: 1rem 2rem;
  position: relative;
  margin-bottom: 8rem;
  top: 15%;

  &::before {
    content: "Current Jobdiva rates";
    position: absolute;
    bottom: 103%;
    font-size: 1rem;
  }

  &::after {
    content: "New Rates";
    position: absolute;
    bottom: 104%;
    left: 59%;
    font-size: 1rem;
    font-weight: 500;
  }
}

.sow_current_rates {
  height: max-content;
  border-radius: 5px;
  width: 35%;
  border: 1px solid #a199c2;
}
.sow_divider {
  width: 20%;
  height: 1rem;
  position: relative;
  top: 10%;
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: #007f9c;
  }
  &::after {
    content: "\2303";
    transform: rotate(89deg);
    position: absolute;
    font-size: 1.5rem;
    left: 90%;
    bottom: 4%;
    color: #007f9c;
  }
}
.sow_new_rates {
  height: max-content;
  width: 35%;
  border-radius: 5px;
  border: 2px solid #007f9c;
}

.sow_current_rate_values {
  height: 25%;
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;

  &:nth-child(even) {
    background-color: rgba(161, 153, 194, 0.15);
  }
  &:nth-child(odd) {
    background-color: #ffffff;
  }
}

.sow_new_rate_values {
  height: 25%;
  flex: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:nth-child(even) {
    background-color: rgba(213, 247, 255, 0.2);
  }
  &:nth-child(odd) {
    background-color: #ffffff;
  }
}

.sow_footer {
  display: flex;
  justify-content: space-between;

  margin: 1rem 2rem;
  justify-content: end;
  top: 8%;
  width: 100%;
  padding-right: 3rem;
}
.sow_checkbox_style {
  display: flex;
  align-items: center;
  gap: 3px;
}

.sow_visible_tip {
  bottom: 30px;
  left: -12px;
  display: none;
}

.sow_container_tip {
  cursor: pointer;
}

.sow_container_tip:hover .sow_visible_tip {
  display: block;
}

.sow_button_alignment {
  display: flex;
}

.sow_btn_cancel_styling {
  width: max-content;
  cursor: pointer;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  background-color: #f5f3f7;
  color: #1559ea;
  border: none;
  &:hover:not(:active, :disabled) {
    background: #f5f3f7;
    color: #1559ea;
  }
  &:active {
    background: #f5f3f7;
  }
}
.sow_btn_update_styling {
  width: max-content;
  cursor: pointer;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  background-color: #1559ea;
  color: white;
  border: none;
  &:hover:not(:active, :disabled) {
    background: #3270eb;
    box-shadow: 0px 1px 2px 0px rgba(21, 89, 234, 0.08),
      0px 2px 4px 0px rgba(21, 89, 234, 0.12),
      0px 4px 8px 0px rgba(21, 89, 234, 0.16),
      0px 8px 16px 0px rgba(21, 89, 234, 0.2);
    backdrop-filter: blur(100px);
  }
  &:active {
    background: #1046b8;
  }
  &:disabled {
    background: rgba(21, 89, 234, 0.25);
    color: white;
  }
}
.sow_client_details_parent {
  display: flex;
  flex-direction: row;
  margin: 0 2rem;
  width: 90%;
  gap: 2rem;
  top: 20%;
  & > div {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #bcb8cb;
    padding-right: 1rem;
    &:last-child {
      border: none;
    }
    & > span:first-child {
      font-size: 0.7rem;
      opacity: 0.7;
      font-weight: normal;
      padding-bottom: 5px;
    }
    & > span:last-child {
      font-weight: 500;
      color: #100a27;
    }
  }
}
.sow_header {
  color: #352281;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  top: 12%;
  margin: 0 2rem;
}
