.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.main {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}
.simulationSearch {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  h2 {
    color: rgb(53, 34, 129);
    font-size: 36px;
    font-family: "Museo Slab";
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 48px;
  }
}
